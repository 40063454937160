import { GetterTree, Module, MutationTree } from 'vuex';
import { RootStore } from '@/features/core/store';
import { CoreTreeNodeBarControlTreeNodeFragment } from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlTreeNodeFragment';
import { HeatingSystemType } from '@/util/heating-systems';

type TreeNode = CoreTreeNodeBarControlTreeNodeFragment;

export type HeatingSystemsState = {
  showAdvancedView: boolean;
  showAdvancedViewGraph: boolean;
  showEditMetricsModal: boolean;
  selectedGraphObject: any;
  treeNodes: TreeNode[];
  advancedViewFilters: HeatingSystemType[];
  advancedViewSearchQuery: string | null;
};

const state: HeatingSystemsState = {
  showAdvancedView: false,
  showAdvancedViewGraph: false,
  showEditMetricsModal: false,
  selectedGraphObject: null,
  treeNodes: [],
  advancedViewFilters: [],
  advancedViewSearchQuery: null,
};
const getters: GetterTree<HeatingSystemsState, RootStore> = {
  showAdvancedView: (state) => state.showAdvancedView,
  showAdvancedViewGraph: (state) => state.showAdvancedViewGraph,
  showEditMetricsModal: (state) => state.showEditMetricsModal,
  treeNodes: (state) => state.treeNodes,
  advancedViewFilters: (state) => state.advancedViewFilters,
  advancedViewSearchQuery: (state) => state.advancedViewSearchQuery,
};
const mutations: MutationTree<HeatingSystemsState> = {
  showAdvancedView(state, value: boolean) {
    state.showAdvancedView = value;
  },
  showAdvancedViewGraph(state, value: boolean) {
    state.showAdvancedViewGraph = value;
  },
  showEditMetricsModal(state, value: boolean) {
    state.showEditMetricsModal = value;
  },
  setTreeNodes(state, value: TreeNode[]) {
    state.treeNodes = value;
  },
  setSelectedGraphObject(state, value: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    state.selectedGraphObject = value;
  },
  setAdvancedViewFilters(state, value: HeatingSystemType[]) {
    state.advancedViewFilters = value;
  },
  setAdvancedViewSearchQuery(state, value: string | null) {
    state.advancedViewSearchQuery = value;
  },
};
const actions = {};

export const HeatingSystemsModule: Module<HeatingSystemsState, any> = {
  state,
  getters,
  mutations,
  actions,
};
