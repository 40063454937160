import { Module, MutationTree, ActionTree, GetterTree, Commit, Dispatch } from 'vuex';
import { RootStore } from '@/features/core/store';
import { HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items } from '@/hsc-api/queries/__generated__/HeatingSystemCollectionWithPaginationQuery';
import { keycloak } from '@/keycloak';
import { DEVICE_REGISTER_SAVE_ENDPOINT, ORIGIN_ENDPOINT_DECODERS } from '@/env';
import { heatingSystemStatus } from '@/features/app-heating-system/views/control-values/constants';

export type ControlModel = {
  name: string;
  heatingSystemId: string;
  deviceSerialNumber: string;
};

export type FilteringStatus = 'inProgress' | 'change' | 'edit' | 'open' | null;

export type DataType = 'float16' | 'float32' | 'int16' | 'uint16' | 'uint32';
export type TableData = 'table' | 'graph' | 'edit';

export type Register = {
  registerNumber: string;
  registerName: string;
  registerFormat: string;
  metricName: string;
  unit: string;
  dataType: DataType;
  offset: number;
  scaleFactor: number | null;
  functionCode: number;
  intervalSeconds: number;
  tableData: TableData;
  standardMetric: boolean;
};

export type Device = {
  deviceRole: string;
  deviceManufacturer: string;
  deviceModel: string;
  deviceVersion: string;
  registers: Register[];
};

export type ApiResponse = Device[];

export type RegisterValue = {
  registerName: string;
  value: number;
};

export type SaveRegisterRequest = {
  deviceRole: string;
  manufacturer: string;
  model: string;
  version?: string;
  registers: (string | number)[];
};

type HeatingSystemControlValues = {
  // Heating systems
  activeHeatingSystemId: string | null;
  heatingSystems: HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items[];
  heatingSystemSearchTerm: string;
  heatingSystemFilteringStatus: FilteringStatus;

  // Control types
  activeControlModelName: string | null;
  controlModels: ControlModel[];

  // Clusters
  activeClusterName: string | null;
  clusters: any[];

  // Register values
  devices: Device[];
  registerValues: RegisterValue[];
};

// Initial state of the project
const state: HeatingSystemControlValues = {
  activeControlModelName: null,
  activeHeatingSystemId: null,
  heatingSystemSearchTerm: '',
  heatingSystemFilteringStatus: null,
  activeClusterName: null,
  controlModels: [],
  heatingSystems: [],
  clusters: [],
  registerValues: [],
  devices: [],
};

// Mutations
const mutations: MutationTree<HeatingSystemControlValues> = {
  setActiveControlModel(state, activeControlModelName: string) {
    state.activeControlModelName = activeControlModelName;
    // Clear downstream selections
    state.activeHeatingSystemId = null;
    state.activeClusterName = null;
  },
  setControlModels(state, controlModels: ControlModel[]) {
    state.controlModels = controlModels;
  },
  setHeatingSystemSearchTerm(state, searchTerm: string) {
    state.heatingSystemSearchTerm = searchTerm;
  },
  setHeatingSystemFilteringStatus(state, status: FilteringStatus) {
    state.heatingSystemFilteringStatus = status;
  },
  setActiveCluster(state, activeClusterName: string) {
    state.activeClusterName = activeClusterName;
  },
  setClusters(state, clusters: any[]) {
    state.clusters = clusters;
  },
  setActiveHeatingSystem(state, activeHeatingSystemId: string) {
    state.activeHeatingSystemId = activeHeatingSystemId;
    // Clear downstream selection
    state.activeClusterName = null;
  },
  setHeatingSystems(state, heatingSystems: HeatingSystemCollectionWithPaginationQuery_heatingSystemsList_items[]) {
    state.heatingSystems = heatingSystems;
  },
  setDevices(state, devices: Device[]) {
    state.devices = devices;
  },
  setRegisterValues(state, registerValues: RegisterValue[]) {
    state.registerValues = registerValues;
  },
};

export type Cluster = {
  name: string;
  registerName: string;
  deviceModel: string;
};

interface SaveRegisterResponse {
  numberOfMessages: number;
  messages: Array<{
    registers: string[];
  }>;
}

// Actions
const actions: ActionTree<HeatingSystemControlValues, any> = {
  async loadDevices({ commit, state }) {
    try {
      const role = 'HEATING_CONTROL';
      const response = await fetch(`${ORIGIN_ENDPOINT_DECODERS}/modbus/api/decoders?role=${encodeURIComponent(role)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${keycloak?.token ?? ''}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: ApiResponse = (await response.json()) as ApiResponse;
      commit('setDevices', data);
      return data;
    } catch (error) {
      console.error(`Failed to load devices from ${ORIGIN_ENDPOINT_DECODERS}:`, error);
      throw error;
    }
  },
  async loadClusters({ commit }) {
    // Load the clusters from the browsers local storage
    const clusters = localStorage.getItem('clusters');
    if (clusters) {
      commit('setClusters', JSON.parse(clusters));
    }
  },
  async saveRegisterValues({ state }, registerValues: RegisterValue[]): Promise<void> {
    try {
      // Get the active control model to get the device serial number
      const activeModel = state.controlModels.find((model) => model.name === state.activeControlModelName);
      if (!activeModel) {
        throw new Error('No active control model selected');
      }

      // Get all devices matching the active model
      const matchingDevices = state.devices.filter((device) => device.deviceModel === state.activeControlModelName);
      if (matchingDevices.length === 0) {
        throw new Error('No matching devices found for selected model');
      }

      // Create and execute save requests for each device
      const savePromises = matchingDevices.map(async (device) => {
        // Filter register values that belong to this device
        const deviceRegisters = registerValues.filter((regValue) =>
          device.registers.some((reg) => reg.registerName === regValue.registerName),
        );

        if (deviceRegisters.length === 0) {
          return null; // Skip devices with no matching registers
        }

        // Transform to flat array format
        const flatRegisters = deviceRegisters.reduce<(string | number)[]>((acc, curr) => {
          acc.push(curr.registerName, curr.value);
          return acc;
        }, []);

        const payload: SaveRegisterRequest = {
          deviceRole: device.deviceRole,
          manufacturer: device.deviceManufacturer,
          model: device.deviceModel,
          registers: flatRegisters,
        };

        if (device.deviceVersion) {
          payload.version = device.deviceVersion;
        }

        const response = await fetch(
          `${DEVICE_REGISTER_SAVE_ENDPOINT}/modbus-processor/device/${activeModel.deviceSerialNumber}/modbus/write`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${keycloak?.token ?? ''}`,
            },
            body: JSON.stringify(payload),
          },
        );

        if (!response.ok) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const errorData = await response.json().catch(() => null);
          throw new Error(
            `Failed to save registers: ${response.status} ${
              errorData ? JSON.stringify(errorData) : response.statusText
            }`,
          );
        }

        const data = (await response.json()) as SaveRegisterResponse;
        if (!data.numberOfMessages || !Array.isArray(data.messages)) {
          throw new Error('Invalid response format');
        }

        return data;
      });

      // Filter out null promises and execute all valid requests
      const validSavePromises = (await Promise.all(savePromises)).filter(
        (result): result is { numberOfMessages: number; messages: any[] } => result !== null,
      );

      if (validSavePromises.length === 0) {
        throw new Error('No valid register values were saved');
      }

      // Success - all promises resolved
      return;
    } catch (error) {
      console.error('Failed to save register values:', error);
      throw error;
    }
  },
};

// Getters
const getters: GetterTree<HeatingSystemControlValues, RootStore> = {
  filteredControlModels: (state) => {
    return state.controlModels;
  },
  filteredHeatingSystems: (state) => {
    const activeControlModel = state.controlModels.find((model) => model.name === state.activeControlModelName);
    if (!activeControlModel) {
      return [];
    }
    const filteredHeatingSystems = state.heatingSystems.filter((system) => {
      return (
        activeControlModel.heatingSystemId === system.id &&
        (state.heatingSystemSearchTerm === '' ||
          system.name.toLowerCase().includes(state.heatingSystemSearchTerm.toLowerCase()))
      );
    });
    // Determine statuses for heating systems
    // a status indicator is displaying if settings for this heating system haven been done
    // in Bearbeitung = heating system selected
    // offen = no settings done so far
    // ändern = all settings done
    // bearbeiten = not all settings done
    const heatingSystemsWithStatuses = filteredHeatingSystems.map((system) => {
      if (system.id === state.activeHeatingSystemId) {
        return { ...system, status: 'inProgress' };
      }
      // ändern
      // get the register values of the heating system and check if they have values
      const heatingSystemControlModels = state.controlModels.filter((model) => model.heatingSystemId === system.id);
      if (heatingSystemControlModels.length === 0) {
        return { ...system, status: 'offen' };
      }
      for (const model of heatingSystemControlModels) {
        const matchingDevices = state.devices.filter((device) => device.deviceModel === model.name);
        if (matchingDevices.length === 0) {
          return { ...system, status: 'offen' };
        }
        for (const device of matchingDevices) {
          if (device.registers.length === 0) {
            return { ...system, status: 'offen' };
          }
          for (const register of device.registers) {
            const registerValue = state.registerValues.find((value) => value.registerName === register.registerName);
            if (!registerValue) {
              return { ...system, status: 'offen' };
            }
            if (!registerValue.value) {
              return { ...system, status: 'offen' };
            }
          }
          return { ...system, status: 'ändern' };
        }
      }

      return { ...system, status: 'offen' };
    });
    if (state.heatingSystemFilteringStatus) {
      const statusLabel = heatingSystemStatus.find(
        (status) => status.value === state.heatingSystemFilteringStatus,
      )?.label;
      return heatingSystemsWithStatuses.filter((system) => system.status === statusLabel);
    }
    return heatingSystemsWithStatuses;
  },
  filteredClusters: (state) => {
    // Filter the active control model from the clusters
    if (!state.activeControlModelName) return [];

    return state.clusters.filter(
      (cluster: Cluster) => cluster.deviceModel === state.activeControlModelName,
    ) as Cluster[];
  },
  filteredRegisterValues: (state) => {
    if (!state.activeControlModelName) return [];

    const matchingDevices = state.devices.filter((device) => device.deviceModel === state.activeControlModelName);
    if (matchingDevices.length === 0) return [];

    // Collect and flatten registers from all matching devices
    return matchingDevices
      .flatMap((device) => device.registers)
      .filter((register) => {
        // Always filter for editable registers
        const isEditable = register.tableData === 'edit';

        // Only apply cluster filter if a cluster is selected
        const matchesCluster = !state.activeClusterName || register.registerName === state.activeClusterName;

        return isEditable && matchesCluster;
      });
  },
};

// Export the module
export const EditHeatingSystemRegistersModule: Module<HeatingSystemControlValues, any> = {
  state,
  mutations,
  actions,
  getters,
};
