import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { APP_HEATING_SYSTEM } from './app.builder';
import { component } from '../component';
import { Route } from 'vue-router';
const uuidCheck = '([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';

export default option(ROUTES, async () => {
  return [
    {
      path: '/heating-system',
      name: 'AppHeatingSystem',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_HEATING_SYSTEM },
      beforeEnter: (to, from, next) => {
        if (from.params && from.params.treeNodeId) {
          next({
            name: 'AppHeatingSystem/TreeNode',
            params: {
              treeNodeId: from.params.treeNodeId,
            },
          });
        } else {
          next();
        }
      },
    },
    {
      path: `/heating-system/:treeNodeId${uuidCheck}`,
      name: 'AppHeatingSystem/MainView',
      component: component('views/tree-node/TreeNodeView.vue'),
      props: true,
      meta: {
        app: APP_HEATING_SYSTEM,
        key: (route: Route): string => `AppHeatingSystem/TreeNode?${route.params.treeNodeId}`,
      },
      children: [
        {
          path: `/heating-system/:treeNodeId${uuidCheck}`,
          name: 'AppHeatingSystem/TreeNode',
          component: component('views/tree-node/heating-systems/HeatingSystemsView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: `/heating-system/list/:treeNodeId${uuidCheck}`,
          name: 'AppHeatingSystem/HeatingSystemList',
          component: component('views/tree-node/heating-systems-list/HeatingSystemsListView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: 'alerts',
          name: 'AppHeatingSystem/TreeNode/Alerts',
          component: component('views/tree-node/alerts/AlertsView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: 'notifications',
          name: 'AppHeatingSystem/TreeNode/Notifications',
          component: component('views/tree-node/notifications/NotificationsView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: 'settings',
          name: 'AppHeatingSystem/TreeNode/Settings',
          component: component('views/tree-node/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: 'directory-spots',
          name: 'AppHeatingSystem/TreeNode/DirectorySpots',
          component: component('views/tree-node/directory-spots/DirectorySpotsView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
      ],
    },
    {
      path: `/heating-system/:treeNodeId${uuidCheck}/registers`,
      name: 'AppHeatingSystem/EditRegisters',
      component: component('views/control-values/EditRegistersView.vue'),
      props: true,
      meta: { app: APP_HEATING_SYSTEM },
    },
    {
      path: `/heating-system/:treeNodeId${uuidCheck}/heating-systems/:heatingSystemId${uuidCheck}`,
      component: component('views/heating-system/HeatingSystemView.vue'),
      props: true,
      meta: { app: APP_HEATING_SYSTEM },
      children: [
        {
          path: `/heating-system/:treeNodeId${uuidCheck}/heating-systems/:heatingSystemId${uuidCheck}`,
          name: 'AppHeatingSystem/HeatingSystem',
          component: component('views/heating-system/general/GeneralView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: `/heating-system/:treeNodeId${uuidCheck}/heating-systems/:heatingSystemId${uuidCheck}/measurements`,
          name: 'AppHeatingSystem/HeatingSystem/Measurements',
          component: component('views/heating-system/measurements/MeasurementsView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: `/heating-system/:treeNodeId${uuidCheck}/heating-systems/:heatingSystemId${uuidCheck}/consumers`,
          name: 'AppHeatingSystem/HeatingSystem/Consumers',
          component: component('views/heating-system/consumers/ConsumersView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: `/heating-system/:treeNodeId${uuidCheck}/heating-systems/:heatingSystemId${uuidCheck}/settings`,
          name: 'AppHeatingSystem/HeatingSystem/Settings',
          component: component('views/heating-system/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
        {
          path: 'gateways',
          name: 'AppHeatingSystem/HeatingSystem/Gateways',
          component: component('views/heating-system/gateways/GatewaysView.vue'),
          props: true,
          meta: { app: APP_HEATING_SYSTEM },
        },
      ],
    },
  ];
});
