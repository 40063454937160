import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_CUSTOMER } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/customer/:customerId/vendor-apis/uvi-export/:vendorApiId',
      component: component('views/uvi-export/UviExportConfigurationView.vue'),
      props: true,
      meta: { app: APP_CUSTOMER, key: (): string => `AppCustomer/VendorApis/UviExport` },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/uvi-export/:vendorApiId',
          name: 'AppCustomer/VendorApis/UviExport',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/UviExport/Settings', params }),
        },
        {
          path: 'settings',
          name: 'AppCustomer/VendorApis/UviExport/Settings',
          component: component('views/uvi-export/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
  ];
});
