import { EnterpriseProjectObjectProduct } from '@/types/iot-portal';

// useCaseOptions.ts
import { EnterpriseProjectObjectProductOption } from '@/types/iot-portal';
import { IWCloudType } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

export enum EnterpriseProjectObjectUseCase {
  HEATING_MONITORING = 'HEATING_MONITORING',
  HEATING_PROFESSIONAL = 'HEATING_PROFESSIONAL',
  ELEVATOR_MONITORING = 'ELEVATOR_MONITORING',
  SMART_ELEVATOR_MAINTENANCE = 'SMART_ELEVATOR_MAINTENANCE',
  TGA_DIGITIZATION = 'TGA_DIGITIZATION',
  TGA_MONITORING = 'TGA_MONITORING',
  TGA_INTEGRATION = 'TGA_INTEGRATION',
  REMOTE_METER_READING = 'REMOTE_METER_READING',
  SMG = 'SMG',
  SERVICE_MANAGEMENT = 'SERVICE_MANAGEMENT',
  CONSUMPTION_DATA_ACQUISITION = 'CONSUMPTION_DATA_ACQUISITION',
  CONSUMPTION_INFORMATION_DURING_THE_YEAR = 'CONSUMPTION_INFORMATION_DURING_THE_YEAR',
  SMOKE_ALARM_MONITORING = 'SMOKE_ALARM_MONITORING',
}

export enum EnterpriseProjectServiceType {
  GK_HUB_L_CABLE = 'GK_HUB_L_CABLE',
  GK_HUB_L_GDSP_MOBILFUNK = 'GK_HUB_L_GDSP_MOBILFUNK',
  GK_HUB_G = 'GK_HUB_G',
}

export const heizungUseCaseOptions = [
  {
    label: 'Heizungsmonitoring (HzM)',
    value: EnterpriseProjectObjectUseCase.HEATING_MONITORING,
  },
  {
    label: 'Heizungsprofi (HzP)',
    value: EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL,
  },
];

export const aufzugUseCaseOptions = [
  {
    label: 'Aufzugsmonitoring (AzM)',
    value: EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING,
  },
  {
    label: 'SmarteAufzugswartung (SAM)',
    value: EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE,
  },
];

export const mainServiceOptions = [
  {
    label: 'GK-Hub L Kabel',
    value: EnterpriseProjectServiceType.GK_HUB_L_CABLE,
  },
  {
    label: 'GK-Hub L GDSP Mobilfunk',
    value: EnterpriseProjectServiceType.GK_HUB_L_GDSP_MOBILFUNK,
  },
];

export const defaultProducts = [
  {
    option: null,
    productType: EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
  },
  {
    option: IWCloudType.STANDARD,
    productType: EnterpriseProjectObjectProduct.IW_CLOUD,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
  },
];

export const infrastructureOptions = [
  {
    label: 'Start',
    value: EnterpriseProjectObjectProductOption.START,
  },
  {
    label: 'Basis',
    value: EnterpriseProjectObjectProductOption.BASIS,
  },
  {
    label: 'Basis+',
    value: EnterpriseProjectObjectProductOption.BASIS_PLUS,
  },
  {
    label: 'Pro',
    value: EnterpriseProjectObjectProductOption.PRO,
  },
];

export const defaultUseCaseOptions = [
  {
    label: 'Heizung',
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: null,
  },
  {
    label: 'Aufzug',
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: null,
  },
  {
    label: 'Digitalisierung TGA (DTGA)',
    checked: false,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.TGA_DIGITIZATION,
  },
  {
    label: 'TGA Monitoring (TGAM)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.TGA_MONITORING,
  },
  {
    label: 'TGA Integration (TGAI)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.TGA_INTEGRATION,
  },
  {
    label: 'Zählerfernauslesung (ZFA)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.REMOTE_METER_READING,
  },
  {
    label: 'Wettbewerbliche Messstellenbetrieb (SMG)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.SMG,
  },
  {
    label: 'Dienstleistungsmanagement (DLM)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT,
  },
  {
    label: 'Verbrauchsdatenerfassung (VDE)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION,
  },
  {
    label: 'Unterjährige Verbrauchsinformation (UVI)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR,
  },
  {
    label: 'Rauchwarnmeldermonitoring (RWM)',
    checked: false,
    monthlyPriceInEuros: null,
    oneTimePriceInEuros: null,
    useCaseType: EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING,
  },
];
