import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { EnterpriseProjectType } from '@/types/iot-portal';

export function isMainServiceRequired(projectType: EnterpriseProjectType): boolean {
  // Main Service not mandatory in a “Preisänderung”, “Produkterweiterung” and “Produktänderung”
  return (
    projectType !== EnterpriseProjectType.PRICING_CHANGES &&
    projectType !== EnterpriseProjectType.NEW_PRODUCT_NEW_USE_CASE &&
    projectType !== EnterpriseProjectType.NEW_PRODUCT_ORIGINAL_USE_CASE
  );
}

// We need to check whether all liegenshaft buildings have at least one product or use case selected
// eslint-disable-next-line complexity
export function doesBuildingHaveInfo(building: ProjectBuilding, projectType: EnterpriseProjectType): boolean {
  if (building.liegenshaft) {
    if (!building.mainService && isMainServiceRequired(projectType)) {
      return false;
    }
  }
  return true;
}
