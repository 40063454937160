

















































































import { DeviceRoleAggregationMetric } from '@/features/core/model';
import { MetricPoint, normalizeMetricPoint } from '@/features/core/util/metrics';
import { Column } from '@/features/ui/table/model';
import { MetricResolutionAggregator, SpotRole } from '@/types/iot-portal';
import { ArrayProp, EnumProp, FunctionProp, IntegerProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import { CoreSpotMetricsPanelControlAggregationQuery } from './__generated__/CoreSpotMetricsPanelControlAggregationQuery';
import { CoreSpotMetricsPanelControlHistoryQuery } from './__generated__/CoreSpotMetricsPanelControlHistoryQuery';
import { CoreSpotMetricsPanelControlSpotQuery } from './__generated__/CoreSpotMetricsPanelControlSpotQuery';
import { checkMetricName } from '../../util/metric-calculations';

@Component
export default class Table extends Mixins(DeviceRoleMapMixin) {
  @ObjectProp(true)
  private readonly spot!: CoreSpotMetricsPanelControlSpotQuery['spot'];

  @ArrayProp(() => [])
  private readonly selectedMetrics!: string[];

  @ArrayProp(() => [])
  private readonly selectableMetrics!: string[];

  @ArrayProp(() => [])
  private readonly metricRows!: MetricPoint[];

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  @StringProp()
  private readonly selectedHistoryMetric?: string;

  @ObjectProp()
  private readonly aggregation?: CoreSpotMetricsPanelControlAggregationQuery['aggregation'];

  @StringProp()
  private readonly aggregationInterval?: Duration;

  @ObjectProp()
  private readonly history?: CoreSpotMetricsPanelControlHistoryQuery['history'];

  @IntegerProp(0)
  private readonly historyPage!: number;

  @FunctionProp(true)
  private readonly getGdprMetricPoints!: (metricPoints: MetricPoint[], order: 'asc' | 'desc') => MetricPoint[];

  private readonly columns: Column[] = [
    { name: 'name', label: 'Metrik', verticalAlign: 'top', width: '35%' },
    { name: 'aggregator', label: 'Aggregationsfunktion', verticalAlign: 'top', width: '20%' },
    { name: 'value', label: 'Wert', align: 'right', verticalAlign: 'top', width: '15%' },
    { name: 'time', label: 'Letzter Datenpunkt', verticalAlign: 'top', width: '15%' },
    { name: 'history', label: 'Aktion', verticalAlign: 'top', width: '15%' },
  ];

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[this.spot.first.role]?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get shouldAggregateValues(): boolean {
    return this.aggregationInterval !== undefined && this.aggregationMetric !== undefined;
  }

  private get aggregationMetric():
    | CoreSpotMetricsPanelControlAggregationQuery['aggregation']['first']['metrics'][number]
    | undefined {
    return this.aggregation?.first.metrics.find(({ name }) => name === this.selectedHistoryMetric);
  }

  private get aggregationMetricSet(): Set<string> {
    return new Set(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [],
    );
  }

  private get aggregationMetricPoints(): MetricPoint[] {
    if (this.aggregationMetric === undefined) {
      return [];
    }

    return this.getGdprMetricPoints(this.aggregationMetric.points.map(normalizeMetricPoint) as MetricPoint[], 'asc');
  }

  private get importantMetricNames(): string[] {
    if (this.isHeatingControl) {
      return this.metricRows.map((item) => item.name);
    }
    return this.deviceRoleMap[this.spot?.first.role ?? '']?.importantMetricNames ?? [];
  }

  private get historyMetric():
    | CoreSpotMetricsPanelControlHistoryQuery['history']['first']['metrics'][number]
    | undefined {
    return this.history?.first.metrics.find(({ name }) => name === this.selectedHistoryMetric);
  }

  private get historyMetricPoints(): MetricPoint[] {
    if (this.historyMetric === undefined) {
      return [];
    }

    // OH: this works around TS limitations
    return this.getGdprMetricPoints(
      this.historyMetric.__typename === 'ContinuousMetric'
        ? (this.historyMetric.points.map(normalizeMetricPoint) as MetricPoint[])
        : (this.historyMetric.points.map(normalizeMetricPoint) as MetricPoint[]),
      'desc',
    );
  }

  private get isHeatingControl(): boolean {
    return this.spot?.first.role === SpotRole.HEATING_CONTROL;
  }

  private timestamp(row: MetricPoint): string {
    if (!this.aggregationMetricMap.has(row.name)) {
      return moment.utc(row.time).format('L LTS');
    }

    switch (this.aggregationInterval) {
      case 'P1M':
        return moment.utc(row.time).format('MM.YYYY');
      case 'P1W':
        return moment.utc(row.time).format('WW.YYYY');
      case 'P1D':
        return moment.utc(row.time).format('DD.MM.YYYY');
      case 'PT1H':
        return `${moment.utc(row.time).format('DD.MM.YYYY HH:00')} - ${moment
          .utc(row.time)
          .add(1, 'hour')
          .format('HH:00')}`;
      default:
        return moment.utc(row.time).format('L LTS');
    }
  }

  private hasMetricAggregators(metricName: string): boolean {
    metricName = checkMetricName(metricName);
    return (this.aggregationMetricMap.get(metricName)?.aggregators ?? []).length > 0;
  }

  private isAggregatorAllowed(metricName: string): boolean {
    metricName = checkMetricName(metricName);
    return (
      this.aggregator !== undefined &&
      (this.aggregationMetricMap.get(metricName)?.aggregators.includes(this.aggregator) ?? false)
    );
  }

  private isHistoryAllowed(metricName: string): boolean {
    metricName = checkMetricName(metricName);
    return this.aggregator === undefined || this.isAggregatorAllowed(metricName);
  }

  private isImportantMetric(name: string): boolean {
    name = checkMetricName(name);
    return this.importantMetricNames.includes(name) || this.importantMetricNames.includes(name.replace(/\d+$/, ''));
  }

  private getMetricAggregatorExplanation(): string | undefined {
    switch (this.aggregator) {
      case MetricResolutionAggregator.FIRST:
        return undefined;
      case MetricResolutionAggregator.LAST:
        return undefined;
      case MetricResolutionAggregator.MAX:
        return 'Maximum der Werte';
      case MetricResolutionAggregator.MIN:
        return 'Minimum der Werte';
      case MetricResolutionAggregator.MEAN:
        return 'Durchschnittliche Werte';
      case MetricResolutionAggregator.SUM:
        return 'Kumulierte Werte';
      default:
        return undefined;
    }
  }

  private checkLabel(label: string): string {
    // remove modbus prefix from labels
    return label.startsWith('MBR_') ? label.slice(4) : label;
  }

  private isCsv2Metric(label: string): boolean {
    if (this.spot.first.role === SpotRole.HEATING_CONTROL) {
      return label.startsWith('MBR_') ? false : true;
    }
    return false;
  }
}
