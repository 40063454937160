






































import { ChartSet, ChartStyle, CHART_STYLE_OPTIONS, LineChartType } from '@/components/charts/model';
import { AufzugheldenMetricNames } from '@/features/device-roles/aufzughelden-device/constants';
import { MetricDescriptorInput, MetricResolutionAggregator, MetricResolutionInput, SpotRole } from '@/types/iot-portal';
import { isDef } from '@/util/lang';
import { ArrayProp, DateProp, EnumProp, StringProp } from '@/util/prop-decorators';
import { difference, groupBy, partition } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { AsyncComponent, Component as ComponentInterface } from 'vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DeviceRoleAggregationMetric } from '../../model';
import { formatMetricName, formatMetricValue, isBooleanMetric } from '../../util/metric-formatters';
import { normalizeMetricPoint } from '../../util/metrics';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import aggregationQuery from './aggregation.gql';
import spotQuery from './spot.gql';
import {
  CoreSpotMetricsGraphPanelControlAggregationQuery,
  CoreSpotMetricsGraphPanelControlAggregationQueryVariables,
  CoreSpotMetricsGraphPanelControlAggregationQuery_aggregation_first_metrics,
} from './__generated__/CoreSpotMetricsGraphPanelControlAggregationQuery';
import {
  CoreSpotMetricsGraphPanelControlSpotQuery,
  CoreSpotMetricsGraphPanelControlSpotQueryVariables,
  CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics,
  CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_ContinuousMetric_points,
  CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_DiscreteMetric_points,
} from './__generated__/CoreSpotMetricsGraphPanelControlSpotQuery';
import { Calculation, calculateMetricValue, checkMetricName } from '../../util/metric-calculations';

type ContinuousMetricPoint = CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_ContinuousMetric_points;
type DiscreteMetricPoint = CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_DiscreteMetric_points;
type MetricPointUnion = ContinuousMetricPoint | DiscreteMetricPoint;
type Spot = CoreSpotMetricsGraphPanelControlSpotQuery['spot'];
type AttributeDefinitions = Spot['first']['customer']['attributeDefinitions'];
interface MetricSet {
  label: string;
  name: string;
  color: string | undefined;
  points: {
    x: string;
    y: string | number;
  }[];
}

@Component({
  apollo: {
    spot: {
      query: spotQuery,
      pollInterval: 10000,
      variables(this: SpotMetricsGraphPanelControl): CoreSpotMetricsGraphPanelControlSpotQueryVariables {
        return {
          spotId: this.spotId,
          descriptors: this.sideEffectedMetricNames.map((metricName) => ({
            name: metricName,
            ...this.descriptorDates,
          })),
        };
      },
    },
    aggregation: {
      query: aggregationQuery,
      fetchPolicy: 'no-cache',
      pollInterval: 10000,
      variables(this: SpotMetricsGraphPanelControl): CoreSpotMetricsGraphPanelControlAggregationQueryVariables {
        return {
          spotId: this.spotId,
          descriptors: Array.from(this.aggregationResolutionMap.entries())
            .filter(([name]) => this.metricNames.map((metricName) => checkMetricName(metricName)).includes(name))
            .map(([name, resolution]) => ({
              name: checkMetricName(name),
              resolution,
              take: 100_000,
              ...this.descriptorDates,
            })),
          includeOutsideTemperature: true,
        };
      },
    },
  },
  data() {
    return { spot: undefined, aggregation: undefined, chartStyle: undefined, LineChartType };
  },
})
export default class SpotMetricsGraphPanelControl extends Mixins(DeviceRoleMapMixin) {
  @StringProp(true)
  private readonly spotId!: string;

  @ArrayProp(() => [])
  private readonly metricNames!: string[];

  @ArrayProp(() => [])
  private readonly availableOnGraphMetrics!: string[];

  @DateProp()
  private readonly startDate?: Date;

  @DateProp()
  private readonly stopDate?: Date;

  @StringProp('PT1H')
  private readonly aggregationInterval?: Duration;

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  private readonly spot?: CoreSpotMetricsGraphPanelControlSpotQuery['spot'];
  private readonly aggregation?: CoreSpotMetricsGraphPanelControlAggregationQuery['aggregation'];

  private chartStyle!: ChartStyle;
  private LineChartType!: LineChartType;
  private mergedMetrics:
    | CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics[]
    | CoreSpotMetricsGraphPanelControlAggregationQuery_aggregation_first_metrics[] = [];

  private get graphWidget(): AsyncComponent | ComponentInterface | undefined {
    if (!this.spot) {
      return undefined;
    }
    const graphWidget = this.deviceRoleMap[this.spot.first.role].graphWidget;

    if (graphWidget === undefined || !graphWidget.enabled) {
      return undefined;
    }

    return graphWidget.component;
  }

  private get attributeDefinitions(): AttributeDefinitions | undefined {
    return this.spot?.first.customer.attributeDefinitions;
  }

  private get graphWidgetProps(): Record<string, unknown> | undefined {
    if (!this.spot) {
      return undefined;
    }

    return this.deviceRoleMap[this.spot.first.role].graphWidget?.props ?? {};
  }

  private get descriptorDates(): Pick<MetricDescriptorInput, 'start' | 'stop'> {
    const timezoneOffset = moment(this.startDate).utcOffset();

    const start =
      this.startDate === undefined
        ? undefined
        : moment(this.startDate).utc().add(timezoneOffset, 'minutes').startOf('day').toDate();
    const stop = this.stopDate === undefined ? undefined : moment(this.stopDate).add(1, 'day').startOf('day').toDate();

    return { start, stop };
  }

  private get aggregationMetricSet(): Set<string> {
    return new Set(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [],
    );
  }

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get aggregationResolutionMap(): Map<string, MetricResolutionInput | undefined> {
    return new Map(
      Array.from(this.aggregationMetricMap.entries())
        .filter((entry) => (this.aggregator ? entry[1].aggregators.includes(this.aggregator) : true))
        .map(([aggregationMetricName, metric]: [string, DeviceRoleAggregationMetric]) => {
          if (metric === undefined) {
            return [aggregationMetricName, undefined];
          }

          return [
            aggregationMetricName,
            {
              intervalLength: this.aggregationInterval ?? 'PT1M',
              timeSource: metric.timeSource,
              aggregator: this.aggregator,
            },
          ];
        }),
    );
  }

  private get metricData(): ChartSet[] {
    const metrics = this.spot?.first.metrics ?? [];
    const aggregatedMetrics = this.aggregation?.first.metrics ?? [];

    const combinedMetrics = [...metrics, ...aggregatedMetrics];

    if (combinedMetrics.length === 0) {
      return [];
    }

    if (this.mergedMetrics.length === 0) {
      this.mergedMetrics = combinedMetrics;
    }

    if (this.mergedMetrics.length === combinedMetrics.length) {
      this.mergedMetrics.map((metric) => {
        const combinedMetricIndex = combinedMetrics.findIndex(({ name }) => name === metric.name);
        metric.points = combinedMetrics[combinedMetricIndex].points;
      });
    }

    if (this.mergedMetrics.length > combinedMetrics.length) {
      const extraMetrics = this.mergedMetrics.filter(
        (metric) => !combinedMetrics.some(({ name }) => name === metric.name),
      );
      this.mergedMetrics = this.mergedMetrics.filter((metric) => !extraMetrics.includes(metric));
    }

    if (this.mergedMetrics.length < combinedMetrics.length) {
      const missingMetrics = combinedMetrics.filter(
        (metric) => !this.mergedMetrics.some(({ name }) => name === metric.name),
      );
      this.mergedMetrics = [...this.mergedMetrics, ...missingMetrics];
    }

    let sets = this.mergedMetrics.map((metric) => ({
      label:
        this.spot?.first.metricConfigurations.find(({ name }) => name === metric.name)?.label ??
        formatMetricName(metric.name),
      name: metric.name,
      color: metric.name === 'outsideTemperature' ? '#3b3939' : undefined,
      points:
        metric.__typename === 'ContinuousMetric'
          ? this.getGdprMetricPoints(metric.points)
              .map(normalizeMetricPoint)
              .map(({ time, value }) => ({ x: time, y: value }))
          : this.getGdprMetricPoints(metric.points)
              .map(normalizeMetricPoint)
              .map(({ time, value }) => ({ x: time, y: value })),
    }));

    sets = this.includeCalculatedMetrics(sets);

    sets = this.checkModbusMetrics(sets);

    return sets;
  }

  private checkModbusMetrics(sets: MetricSet[]): MetricSet[] {
    return sets.map((item) => ({
      ...item,
      label: item.label.startsWith('MBR_') ? item.label.slice(4) : item.label,
    }));
  }

  private get propertyId(): string | undefined {
    return this.spot?.first.property?.node?.id;
  }

  private get objectIds(): string[] {
    const result: string[] = [];
    if (this.$router.currentRoute.params.treeNodeId) {
      result.push(this.$router.currentRoute.params.treeNodeId);
    }
    if (this.propertyId) {
      result.push(this.propertyId);
    }
    if (this.spot?.first.id) {
      result.push(this.spot.first.id);
    }
    return result;
  }

  private includeCalculatedMetrics(data: MetricSet[]): MetricSet[] {
    const metric = this.metricNames.length > 0 ? checkMetricName(this.metricNames[0]) : null;
    const calculatedMetrics: MetricSet[] = [];

    // process the calculations only if the selected metrics contains one that ends with "_calc".
    if (this.metricNames.some((str) => str.endsWith('_calc'))) {
      // Get attribute definitions that matches the following criteria:
      // 1. It should be of type calculation attribute.
      // 2. It should match the selected metric name.
      // 3. It should include either current object or spot id.
      const attributeDefinitions = this.attributeDefinitions?.items.filter((item) => {
        return (
          item.configuration.__typename === 'CalculationAttributeConfiguration' &&
          item.configuration.calculations?.metricName === metric &&
          (item.configuration.calculations.spotFilterIds as string[]).some((item) => this.objectIds.includes(item))
        );
      });

      if (attributeDefinitions && attributeDefinitions.length > 0) {
        const attributeDefinition = attributeDefinitions[0];
        if (attributeDefinition.configuration.__typename === 'CalculationAttributeConfiguration') {
          const { calculationType, calculationFactor, calculationType2, calculationFactor2 } = attributeDefinition
            .configuration.calculations as unknown as Calculation;
          // if there's any matching metric from data/metric set, create calculated metrics.
          data.forEach((metricSet) => {
            if (metric === metricSet.name) {
              calculatedMetrics.push({
                label: `${metricSet.label} (Kalkulation)`,
                name: `${metricSet.name}_calc`,
                color: 'orange',
                points: metricSet.points.map((point) => {
                  let calculatedValue = calculateMetricValue(point.y, calculationFactor, calculationType);
                  // additional calculation if there's type2 and factor2
                  if (calculationFactor2 && calculationType2) {
                    calculatedValue = calculateMetricValue(calculatedValue, calculationFactor2, calculationType2);
                  }
                  return {
                    ...point,
                    y: calculatedValue,
                  };
                }),
              });
            }
          });
        }
      }
    }

    // if there's one calculated metric checked, return the calculated data sets only.
    if (this.metricNames.length === 1 && this.metricNames[0].includes('_calc')) {
      return calculatedMetrics;
    } else {
      data.push(...calculatedMetrics);
      return data;
    }
  }

  private yAxisLabelFormatter(value: string | number): string {
    if (this.chartStyle === ChartStyle.STEP && this.metricNames.some(isBooleanMetric)) {
      return value === '0' ? 'no/off' : 'yes/on';
    }
    return this.metricNames.length === 0 ? `${value}` : `${this.formatValue(value, this.metricNames[0]) ?? value}`;
  }

  private formatValue(value: string | number, name: string): string {
    return formatMetricValue(this.metricNames.find((v) => v === name) ?? '', value);
  }

  private chartStyleOptions = CHART_STYLE_OPTIONS;

  @Watch('metricNames')
  private updateSelectedMetrics(): void {
    this.metricNamesSideEffect();
  }

  private get sideEffectedMetricNames(): string[] {
    if (this.metricNames.includes(AufzugheldenMetricNames.DoorCycles)) {
      return difference(
        [...this.metricNames, AufzugheldenMetricNames.DoorOpenings, AufzugheldenMetricNames.DoorClosings],
        Array.from(this.aggregationMetricSet),
      );
    }
    return difference(this.metricNames, Array.from(this.aggregationMetricSet));
  }

  private metricNamesSideEffect(): void {
    if (
      this.spot?.first.role === SpotRole.AUFZUGHELDEN_DEVICE &&
      this.metricNames.includes(AufzugheldenMetricNames.DoorCycles)
    ) {
      this.chartStyle = ChartStyle.BAR;
    }

    for (const metricName of this.metricNames) {
      if (isBooleanMetric(checkMetricName(metricName))) {
        this.chartStyle = ChartStyle.STEP;
        this.$emit('update:selected-metrics', this.metricNames.filter(isBooleanMetric));
        return;
      }
    }

    this.chartStyle = ChartStyle.LINE;
  }

  private getGdprMetricPoints(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
    if (
      ![SpotRole.HEAT_COST_ALLOCATOR, SpotRole.HEAT_METER_COUNTER, SpotRole.WATER_METER_HOT].includes(
        this.spot?.first.role ?? SpotRole.UNKNOWN,
      )
    ) {
      return metricPoints;
    }
    const now = DateTime.utc();

    const [newerThan2Weeks, olderThan2Weeks] = partition(
      metricPoints,
      ({ time }) => DateTime.fromISO(time).plus({ weeks: 2 }) >= now,
    );

    const pointsGroupedByYearMonth = groupBy(
      olderThan2Weeks,
      ({ time }) => `${DateTime.fromISO(time).year}-${DateTime.fromISO(time).month}`,
    );

    const filteredPointsOlderThan2Weeks = Object.values(pointsGroupedByYearMonth).flatMap((points) =>
      this.filter1thAnd16th(points),
    );

    const gdprPoints = [...newerThan2Weeks, ...filteredPointsOlderThan2Weeks].sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
    );

    return gdprPoints;
  }

  private filter1thAnd16th(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
    const firstOfMonth = metricPoints.find(({ time }) => {
      return DateTime.fromISO(time).hasSame(DateTime.fromISO(time).startOf('month'), 'day');
    });

    const sixteenthOfMonth = metricPoints.find(({ time }) => {
      const date = DateTime.fromISO(time);

      return DateTime.fromISO(time).hasSame(DateTime.utc(date.year, date.month, 16), 'day');
    });

    if (firstOfMonth === undefined && sixteenthOfMonth === undefined) {
      return [];
    }

    return [firstOfMonth, sixteenthOfMonth].filter(isDef);
  }

  private get yAxisDimensions(): Array<string | number> {
    if (this.chartStyle === ChartStyle.STEP) {
      return ['0', '1'];
    }

    return [];
  }

  private mounted(): void {
    this.metricNamesSideEffect();
  }
}
