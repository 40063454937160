import {
  CobraTemplateHeadingRow,
  TemplateHeadingRow,
  requiredFields,
} from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/constants';
import { sortProjectDraftBuildings } from '@/features/core/store/page-modules/enterprise-project-create/create-module-helpers';
import { defaultProducts, defaultUseCaseOptions } from '@/features/app-enterprise-project/enterprise-project-constants';
import structuredClone from '@ungap/structured-clone';
import {
  IWCloudType,
  ProjectBuilding,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { EnterpriseProjectObjectProduct } from '@/types/iot-portal';

export interface ProjectBuildingsUpload {
  file: File;
  label: string;
}

export type ImportRowErrors = {
  row: number;
  error: string;
};

export type CovertSheetToDataResult = {
  data: { [key: string]: any }[];
  errors: ImportRowErrors[];
};

interface ImportedRow {
  buildingId: number;
  objectId?: string;
  street?: string;
  houseNumber?: number;
  addressSupplement?: string;
  postalCode?: string;
  city?: string;
  area?: string;
  objectType?: string;
  supplyType?: string;
  frequency?: string;
  orderStartDate?: string;
  vpkn?: string;
  invIdentifier?: string;
  contractStatus?: string;
  revenueYear?: string;
  weEnv?: string;
  tlaName?: string;
  geoLatitude?: string;
  geoLongitude?: string;
  id?: string;
  numberOfApartments?: string;
}

export enum BuildingImportFormat {
  MAPPE,
  COBRA,
}

export interface BuildingImportInfo {
  importFormat: BuildingImportFormat;
  columnHeaderRow: number;
}

function convertToNumber(coordinate: string): number {
  // Replace comma with dot and convert to number
  return parseFloat(coordinate.replace(',', '.'));
}

function checkRowRequiredInformationForErrors(
  row: number,
  entry: { [key: string]: any },
  columnMapping: { [key: string]: string },
): ImportRowErrors[] {
  const errors: ImportRowErrors[] = [];
  // Check if row has all the required fields
  for (const field of requiredFields) {
    if (!entry[field]) {
      // First get the corresponding key from the columnMapping
      const key = Object.keys(columnMapping).find((k) => columnMapping[k] === field);
      if (key) {
        errors.push({
          row,
          error: `Der Wert für die Spalte ${key} ist nicht gültig`,
        });
      }
    }
  }
  return errors;
}

export function addProductsAndUseCases(building: ProjectBuilding): void {
  building.products = [
    {
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      productType: EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
      option: null,
    },
    {
      monthlyPriceInEuros: null,
      oneTimePriceInEuros: null,
      productType: EnterpriseProjectObjectProduct.IW_CLOUD,
      option: IWCloudType.STANDARD,
    },
  ];
  building.useCases = structuredClone(defaultUseCaseOptions);
}

export async function convertSheetToData(
  data: any[],
  columnHeaderRow: number,
  columnMapping: Record<string, string>,
  templateHeadingRow: string[],
): Promise<CovertSheetToDataResult> {
  let errors: ImportRowErrors[] = [];

  if (!data.length) {
    return {
      data: [],
      errors: [{ row: 0, error: 'Keine Daten gefunden' }],
    };
  }

  // Remove rows before and including the header row from the data variable
  for (let i = 0; i < columnHeaderRow; i++) {
    data.shift();
  }

  const objectIdSet = new Set<string>();
  const importedData = [];
  // Map the data to the desired format
  for (let i = 0; i < data.length; i++) {
    const rowNumber = i + columnHeaderRow + 1;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (data[i].length > 0) {
      const mappedRow: ImportedRow = { buildingId: i + 1 };
      // The columnMapping contains the columns that we extract
      for (const key in columnMapping) {
        // Get the index from the key from the templateHeadingRow array
        const columnIndex = templateHeadingRow.indexOf(key);
        // If there is an unknown column then stop the process
        if (columnIndex === -1 && requiredFields.includes(columnMapping[key])) {
          errors.push({
            row: rowNumber,
            error: `Der Wert für die Spalte ${key} ist nicht gültig`,
          });
          continue;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (data[i]?.[columnIndex] !== undefined) {
          /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mappedRow[columnMapping[key]] = data[i][columnIndex];
          /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
        }
      }
      // Check if the row has the required information
      const rowErrors = checkRowRequiredInformationForErrors(rowNumber, mappedRow, columnMapping);

      if (mappedRow.objectId) {
        // Check that the object id has not been duplicated
        if (objectIdSet.has(mappedRow.objectId)) {
          // Duplicate detected
          rowErrors.push({ row: rowNumber, error: 'Der Wert der Objekt-ID wurde dupliziert' });
        } else {
          // New object id
          objectIdSet.add(mappedRow.objectId);
        }
      }

      errors = errors.concat(rowErrors);

      if (rowErrors.length === 0) {
        importedData.push(mappedRow);
      }
    }
  }

  const buildings: ProjectBuilding[] = getProjectBuildings(importedData);
  return { data: buildings, errors };
}

export function getBuildingImportInfo(data: any[]): BuildingImportInfo | null {
  let importFormat: BuildingImportFormat | null = null;
  let columnHeaderRow: number | null = null;

  if (CobraTemplateHeadingRow.length == 0 || TemplateHeadingRow.length == 0) {
    return null;
  }

  // Determine import format based on first column's heading
  for (let i = 0; i < data.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (data[i][0] == CobraTemplateHeadingRow[0]) {
      importFormat = BuildingImportFormat.COBRA;
      columnHeaderRow = i + 1;
      break;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (data[i][0] == TemplateHeadingRow[0]) {
      importFormat = BuildingImportFormat.MAPPE;
      columnHeaderRow = i + 1;
      break;
    }
  }

  const info = importFormat != null && columnHeaderRow != null ? { importFormat, columnHeaderRow } : null;

  return info;
}

function getProjectBuildings(importedData: ImportedRow[]): ProjectBuilding[] {
  let buildings: ProjectBuilding[] = importedData.map((building: ImportedRow) => {
    const convertedBuilding: ProjectBuilding = {
      id: Number(building.objectId),
      name: `${building.street ? building.street : ''} ${building.houseNumber ? building.houseNumber : ''} ${
        building.addressSupplement ? building.addressSupplement : ''
      }`,
      cityAddress: building.city ? building.city : '',
      houseAddress: Number(building.houseNumber),
      houseAddressSuffix: building.addressSupplement ? building.addressSupplement.toString() : '',
      streetAddress: building.street ? building.street : '',
      postCodeAddress: building.postalCode ? building.postalCode : '',
      locationLongitude: convertToNumber(building.geoLongitude ? building.geoLongitude : '0'),
      locationLatitude: convertToNumber(building.geoLatitude ? building.geoLatitude : '0'),
      numberOfApartments: Number(building.numberOfApartments),
      liegenshaft: false,
      liegenshaftInformation: {
        firstName: '',
        surname: '',
        title: '',
        phoneNumber: '',
        email: '',
      },
      mainService: null,
      buildingId: Number(building.buildingId),
      hasElevator: false,
      comment: null,
      objectId: building.objectId,
    };
    addProductsAndUseCases(convertedBuilding);
    return convertedBuilding;
  });
  // Sort buildings by street, house number, and addressSupplement
  buildings = sortProjectDraftBuildings(buildings);

  return buildings;
}
