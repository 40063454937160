var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.metricRows,"fixed":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-metric',{attrs:{"name":row.name,"spot":_vm.spot.first},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var name = ref.name;
var label = ref.label;
return [_c('ui-input-checkbox',{attrs:{"value":_vm.selectedMetrics.includes(name),"hidden":!_vm.isImportantMetric(name) && !_vm.hasMetricAggregators(name),"disabled":_vm.aggregator !== undefined && !_vm.isAggregatorAllowed(row.name),"full-opacity":""},on:{"update":function($event){return _vm.$emit('select-metric', {selected: $event, name: name})}},scopedSlots:_vm._u([{key:"optionLabel",fn:function(){return [_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.checkLabel(label))+_vm._s('\u2007')),(_vm.isCsv2Metric(label))?_c('span',{staticClass:"text-light"},[_vm._v("["+_vm._s(name)+"]")]):_vm._e()])]},proxy:true}],null,true)})]}}],null,true)})]}},{key:"aggregator",fn:function(ref){
var row = ref.row;
return [(_vm.aggregator===undefined)?[_vm._v("–")]:(_vm.isAggregatorAllowed(row.name))?[_vm._v(_vm._s(_vm.getMetricAggregatorExplanation()))]:_c('span',{staticClass:"text-light"},[_vm._v("Aggregationsfunktion nicht verfügbar")])]}},{key:"value",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_c('domain-ui-metric-value',{attrs:{"value":row.value,"name":row.name,"spot":_vm.spot.first,"unit":row.unit,"inline":""}}),(_vm.selectedHistoryMetric === row.name && _vm.isHistoryAllowed(row.name))?[(_vm.shouldAggregateValues && _vm.aggregationMetricSet.has(row.name))?[_vm._l((_vm.aggregationMetricPoints),function(point){return [_c('br'),_c('domain-ui-metric-value',{attrs:{"value":point.value,"name":point.name,"spot":_vm.spot.first,"unit":point.unit,"inline":""}})]})]:[_vm._l((_vm.historyMetricPoints),function(point){return [_c('br'),_c('domain-ui-metric-value',{attrs:{"value":point.value,"name":point.name,"spot":_vm.spot.first,"unit":point.unit,"inline":""}})]})],_c('br'),_c('ui-wizard-button',{attrs:{"disabled":_vm.historyMetric === undefined || _vm.historyPage === 0},on:{"click":function($event){return _vm.$emit('history-page-change', _vm.historyPage - 1)}}},[_vm._v("Neuere")])]:_vm._e()],2)]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_c('ui-relative-time',{attrs:{"min":"-PT30M","date":row.time,"format":"L LTS"}}),(_vm.selectedHistoryMetric === row.name && _vm.isHistoryAllowed(row.name))?[(_vm.shouldAggregateValues && _vm.aggregationMetricSet.has(row.name))?[_vm._l((_vm.aggregationMetricPoints),function(point){return [_c('br'),_vm._v(_vm._s(_vm.timestamp(point)))]})]:[_vm._l((_vm.historyMetricPoints),function(point){return [_c('br'),_c('ui-relative-time',{attrs:{"min":"-PT30M","date":point.time,"format":"L LTS"}})]})],_c('br'),_c('ui-wizard-button',{attrs:{"disabled":_vm.historyMetric === undefined || !_vm.historyMetric.more},on:{"click":function($event){return _vm.$emit('history-page-change', _vm.historyPage + 1)}}},[_vm._v("Ältere")])]:_vm._e()],2)]}},{key:"history",fn:function(ref){
var row = ref.row;
return [(!_vm.isHistoryAllowed(row.name))?_c('ui-tooltip',{attrs:{"placement":"top","hide-delay":0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v("Historie")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v("Die Historie kann nicht angezeigt werden, da für diese Metrik keine Aggregationsfunktion vorliegt.")]),_c('p',[_vm._v("Bitte wählen Sie die Option keine Aggregation aus und öffnen dann die Historie.")])]},proxy:true}],null,true)}):_c('ui-link',{on:{"click":function($event){return _vm.$emit('history-click', row.name)}}},[_vm._v(_vm._s(_vm.selectedHistoryMetric === row.name ? 'Schließen' : 'Historie'))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }