import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootStore } from '@/features/core/store';
import saveProjectDraft from '@/features/app-enterprise-project/views/enterprise-project-create/save-project-draft.gql';
import createBuildings from '@/features/app-enterprise-project/views/enterprise-project-create/create-buildings-mutation.gql';
import Vue from 'vue';
import { DollarApollo } from 'vue-apollo/types/vue-apollo';
import { SaveProjectDraftMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/SaveProjectDraftMutation';
import {
  hasObjectChanged,
  projectHasElevatorProduct,
  transformDbObjectForState,
  transformStateForSaving,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-helpers';
import {
  BuildingDetails,
  BuildingInformation,
  CreatedBuildingMeta,
  DatabaseProjectDraft,
  EditOptions,
  EnterpriseActions,
  LiegenschaftProduct,
  LiegenschaftUseCase,
  NewProductSelection,
  NewProjectData,
  ProjectBuilding,
  ProjectCreateState,
  ProjectDraftCompleted,
  SaveToDBParams,
  SetBuildingInformationPayload,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { CreateBuildingsMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/CreateBuildingsMutation';
import {
  EnterpriseProjectObjectUseCase,
  mainServiceOptions,
} from '@/features/app-enterprise-project/enterprise-project-constants';
import structuredClone from '@ungap/structured-clone';
import {
  EnterpriseProjectObjectProductOption,
  EnterpriseProjectServiceType,
  EnterpriseProjectType,
} from '@/types/iot-portal';
import { doesBuildingHaveInfo } from '@/features/app-enterprise-project/views/enterprise-project-create/components/step3/step-3-helpers';
import { addProductsAndUseCases } from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/building-importer-helpers';

// Initial state of the project
const state: ProjectCreateState = {
  newProjectData: {
    id: null,
    projectType: EnterpriseProjectType.ORIGINAL,
    projectReference: null,
    parentProjectId: null,
    general: {
      customerName: null,
      customerNumber: null,
      partnerName: null,
      invoiceNumber: null,
      contractTerm: null,
      createNewInvoiceNumber: false,
      referenceToMainContract: false,
      transferToMainContract: false,
      comment: null,
    },
    customerInfo: {
      customerName: null,
      customerNumber: null,
      contactPerson: null,
      invoiceNumber: null,
      contractTerm: null,
      createNewInvoiceNumber: false,
      referenceToMainContract: false,
      transferToMainContract: false,
      comment: null,
    },
    buildings: [],
  },
  activeStep2BuildingId: 0,
  tab: 'step1',
  objectToSave: undefined,
  saving: false,
  // For handling the building selection on step 1.3
  selectedBuildingsForContactInformation: [],
  projectId: null,
  // This is to keep track of what has been completed in the project creation
  generalInformationCompleted: false,
  buildingsImportCompleted: false,
  propertyInformationCompleted: false,
  propertyAssociationCompleted: false,
  editableBuildings: new Map<string, CreatedBuildingMeta>(),
  buildingChangeMode: false,
  buildingChangeList: new Set<string>(),
  buildingsCreated: false,
  originalSaveObject: null,
  associatedBuildings: 0,
  buildingInformationFormValid: false,
  originalMainBuildingIds: [],
  originalBuildingIds: [],
  omittedBuildingIds: [],
  projectCopyChangesId: null,
  // BuildingId is the index with a boolean value to indicate if the building has a comment
  buildingsWithCommentsMap: [],
  // Keep track of which main buildings have been selected on the left panel
  selectedMainBuildingsForComments: [],
  commentTabActiveBuildingId: null,
};

const addBuildingToChangeList = (buildingId: string): void => {
  if (!state.buildingChangeMode) {
    return;
  }

  const idKey = `${buildingId}`;

  const buildingMeta = state.editableBuildings?.get(idKey);

  if (buildingMeta) {
    state.buildingChangeList?.add(buildingMeta.buildingReferenceId);
  }
};

const updateBuildingChangeListByIds = (state: ProjectCreateState, buildingIds: number[]): void => {
  for (const buildingId of buildingIds) {
    addBuildingToChangeList(`${buildingId}`);
  }
};

const buildingHasConfigurations = (building: ProjectBuilding): boolean => {
  return (
    !!building.mainService ||
    building.products?.some((product) => product.monthlyPriceInEuros || product.oneTimePriceInEuros) ||
    building.useCases?.some((useCase) => useCase.monthlyPriceInEuros || useCase.oneTimePriceInEuros) ||
    false
  );
};

// Mutations
const mutations: MutationTree<ProjectCreateState> = {
  setTab(state, tab: 'step1' | 'step2' | 'step3' | 'elevatorSelection' | 'buildingContactInformation' | 'comments') {
    state.tab = tab;
  },
  setSaving(state, saving: boolean) {
    state.saving = saving;
  },
  clearState(state) {
    state.newProjectData = {
      id: null,
      projectReference: null,
      parentProjectId: null,
      projectType: EnterpriseProjectType.ORIGINAL,
      general: {
        customerName: null,
        customerNumber: null,
        partnerName: null,
        invoiceNumber: null,
        contractTerm: null,
        createNewInvoiceNumber: false,
        referenceToMainContract: false,
        transferToMainContract: false,
        comment: null,
      },
      customerInfo: {
        customerName: null,
        customerNumber: null,
        contactPerson: null,
        invoiceNumber: null,
        contractTerm: null,
        createNewInvoiceNumber: false,
        referenceToMainContract: false,
        transferToMainContract: false,
        comment: null,
      },
      buildings: [],
    };
    state.activeStep2BuildingId = 0;
    state.tab = 'step1';
    state.objectToSave = undefined;
    state.saving = false;
    state.generalInformationCompleted = false;
    state.buildingsImportCompleted = false;
    state.propertyInformationCompleted = false;
    state.propertyAssociationCompleted = false;
    state.editableBuildings = new Map<string, CreatedBuildingMeta>();
    state.buildingChangeMode = false;
    state.buildingChangeList = new Set<string>();
    state.buildingsCreated = false;
    state.originalMainBuildingIds = [];
    state.originalBuildingIds = [];
    state.omittedBuildingIds = [];
    state.projectId = null;
    state.projectCopyChangesId = null;
    state.buildingsWithCommentsMap = [];
    state.commentTabActiveBuildingId = null;
  },
  // Edit mode setup
  setBuildingChangeMode(state, buildingChangeMode: boolean) {
    state.buildingChangeMode = buildingChangeMode;
  },
  setCommentTabActiveBuildingId(state, buildingId: number | null) {
    state.commentTabActiveBuildingId = buildingId;
  },
  setEditableBuildings(state, editableBuildings: Map<string, CreatedBuildingMeta>) {
    state.editableBuildings = editableBuildings;
  },
  setNumberOfNonEditableBuildings(state, numberOfNonEditableBuildings: number) {
    state.numberOfNonEditableBuildings = numberOfNonEditableBuildings;
  },
  setBuildingsCreated(state, buildingsCreated: boolean) {
    state.buildingsCreated = buildingsCreated;
  },
  setProjectCopyChangesId(state, projectCopyChangesId: string) {
    state.projectCopyChangesId = projectCopyChangesId;
  },
  setCopyDetails(
    state,
    payload: {
      projectType: EnterpriseProjectType;
      originalMainBuildingIds: number[];
      originalBuildingIds: number[];
      omittedBuildingIds: number[];
    },
  ) {
    state.newProjectData.projectType = payload.projectType;
    state.originalMainBuildingIds = payload.originalMainBuildingIds;
    state.originalBuildingIds = payload.originalBuildingIds;
    state.omittedBuildingIds = payload.omittedBuildingIds;
  },
  // Step 1
  setNewProjectData(state, newProjectGeneralData: NewProjectData) {
    // If the id or projectReference is not set, we set it from the original data
    if (!newProjectGeneralData.id && state.newProjectData.id) {
      newProjectGeneralData.id = state.newProjectData.id;
    }
    if (!newProjectGeneralData.projectReference && state.newProjectData.projectReference) {
      newProjectGeneralData.projectReference = state.newProjectData.projectReference;
    }
    state.newProjectData.id = newProjectGeneralData.id;
    state.newProjectData.projectReference = newProjectGeneralData.projectReference;
    state.newProjectData.general = newProjectGeneralData.general;
  },
  setOriginalData(state, originalProjectData: DatabaseProjectDraft & ProjectDraftCompleted & EditOptions) {
    state.originalSaveObject = originalProjectData;
  },
  setNewBuildingsData(state, buildings: ProjectBuilding[]) {
    state.newProjectData.buildings = buildings;
  },
  setBuildingInformationForBuildingId(state, payload: SetBuildingInformationPayload) {
    const { buildingInformation, buildingId } = payload;
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === buildingId) {
        state.newProjectData.buildings[i].liegenshaftInformation = buildingInformation;
        state.newProjectData.buildings[i].liegenshaftInformationCompleted = true;
      }
    }
    // Clear the selectedBuildingsForContactInformation
    state.selectedBuildingsForContactInformation = [];
  },
  setAllBuildingInformation(state, buildingInformation: BuildingInformation) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].liegenshaft) {
        state.newProjectData.buildings[i].liegenshaftInformation = buildingInformation;
      }
    }
  },
  setGeneralInformationCompleted(state, completed: boolean) {
    state.generalInformationCompleted = completed;
  },
  setBuildingsImportCompleted(state, completed: boolean) {
    state.buildingsImportCompleted = completed;
    if (!completed) {
      state.propertyInformationCompleted = false;
      state.propertyAssociationCompleted = false;
    }
  },
  updateBuildingsImportCompleted(state) {
    if (!state.newProjectData.buildings.some((building) => building.liegenshaft)) {
      state.buildingsImportCompleted = false;
      state.propertyInformationCompleted = false;
    }
  },
  setPropertyInformationCompleted(state, completed: boolean) {
    state.propertyInformationCompleted = completed;
  },
  setPropertyAssociationCompleted(state, completed: boolean) {
    state.propertyAssociationCompleted = completed;
  },
  setBuildingSelectionForContactInformation(state, payload: { buildingId: number; selected: boolean }) {
    state.selectedBuildingsForContactInformation[payload.buildingId] = payload.selected;
  },
  clearBuildingSelectionForContactInformation(state) {
    state.selectedBuildingsForContactInformation = [];
  },
  // Step 2
  setBuildingInformationFormValid(state, valid: boolean) {
    state.buildingInformationFormValid = valid;
  },
  setActiveStep2BuildingId(state, id: number) {
    state.activeStep2BuildingId = id;
  },
  saveBuildingAssociations(state, updatedBuilding: ProjectBuilding) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === updatedBuilding.buildingId) {
        state.newProjectData.buildings[i].parentProperty = updatedBuilding.parentProperty;
      }
    }
    const buildingsWithParents = state.newProjectData.buildings.filter(
      (building: ProjectBuilding) =>
        !building.liegenshaft && building.parentProperty !== undefined && building.parentProperty !== null,
    );
    state.associatedBuildings = buildingsWithParents.length;
  },
  updateBuildingWIENumber(state, { wieNumber, buildingId }: { wieNumber: string; buildingId: number }) {
    // Loop through the buildings and update the wieNumber
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === buildingId) {
        state.newProjectData.buildings[i].wieNumber = wieNumber;
        return;
      }
    }
  },
  updateBuildingInstallationStartDate(
    state,
    { installationStartDate, buildingId }: { installationStartDate: string; buildingId: number },
  ) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === buildingId) {
        state.newProjectData.buildings[i].installationStartDate = installationStartDate;
        return;
      }
    }
  },
  // Step 3
  applyActiveLiegenschaftSettingsToAll(
    state,
    payload: {
      mainService: EnterpriseProjectServiceType;
      products: LiegenschaftProduct[];
      useCases: LiegenschaftUseCase[];
    },
  ) {
    const selectedLiegenshafts = state.newProjectData.buildings.filter((building) => building.liegenshaft);
    selectedLiegenshafts.forEach((liegenshaft) => {
      // apply settings to liegenshafts with empty settings
      const hasProducts = liegenshaft.products?.some(
        (product) => product.monthlyPriceInEuros || product.oneTimePriceInEuros,
      );
      const hasUseCases = liegenshaft.useCases?.some(
        (product) => product.monthlyPriceInEuros || product.oneTimePriceInEuros,
      );

      if (!hasProducts && !hasUseCases) {
        liegenshaft.mainService = payload.mainService;
        liegenshaft.products = payload.products;
        liegenshaft.useCases = payload.useCases;
      }
    });
  },
  applyActiveConfigurations(
    state,
    payload: {
      mainService: EnterpriseProjectServiceType;
      buildingIds: number[];
      products: LiegenschaftProduct[];
      useCases: LiegenschaftUseCase[];
      details: BuildingDetails;
      includesComments: boolean;
    },
  ) {
    const buildings = state.newProjectData.buildings.map((building) => {
      if (payload.buildingIds.includes(building.buildingId)) {
        return {
          ...building,
          mainService: structuredClone(payload.mainService),
          products: structuredClone(payload.products),
          useCases: structuredClone(payload.useCases),
          invoiceName: structuredClone(payload.details.invoiceName),
          serviceLevel: structuredClone(payload.details.serviceLevel),
          preInspection: structuredClone(payload.details.preInspection),
          includesComments: structuredClone(payload.includesComments),
        };
      }
      return building;
    });
    Vue.set(state.newProjectData, 'buildings', buildings);
  },
  updateLiegenschaftProducts(state, payload: { id: number; products: LiegenschaftProduct[] }) {
    const { id, products } = payload;
    const building = state.newProjectData.buildings.find((building) => building.buildingId === id);
    if (building) {
      building.products = products;
    }
  },
  // Elevator step
  // Set a building as having an elevator
  setBuildingHasElevator(state, { buildingId, hasElevator }) {
    // Iterate through the buildings in the state
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === buildingId) {
        // Use Vue.set to ensure reactivity when updating the 'hasElevator' property
        Vue.set(state.newProjectData.buildings[i], 'hasElevator', hasElevator);
        break;
      }
    }
  },
  updateBuilding(state, building: ProjectBuilding) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === building.buildingId) {
        state.newProjectData.buildings[i] = building;
        break;
      }
    }
  },
  configureMainBuildingLinks(state) {
    const mainBuildings = state.newProjectData.buildings.filter((building) => building.liegenshaft);
    const mainBuildingIds = mainBuildings.map((building) => building.buildingId);
    // Check if copy
    const projectIsCopy = state.newProjectData.projectType !== EnterpriseProjectType.ORIGINAL;
    // Put the difference between mainBuildingIds and originalMainBuildingIds in the omittedBuildingIds
    if (projectIsCopy) {
      // The child properties of main building should not be available for reselect if copied project
      state.omittedBuildingIds = state.originalMainBuildingIds.filter((id) => !mainBuildingIds.includes(id));
    }
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].liegenshaft) {
        state.newProjectData.buildings[i].parentProperty = null;
        if (!state.newProjectData.buildings[i].useCases || !state.newProjectData.buildings[i].products) {
          addProductsAndUseCases(state.newProjectData.buildings[i]);
        }
      }
      if (
        state.newProjectData.buildings[i].parentProperty &&
        !mainBuildingIds.includes(state.newProjectData.buildings[i].parentProperty as number)
      ) {
        state.newProjectData.buildings[i].parentProperty = null;
        if (projectIsCopy) {
          state.omittedBuildingIds.push(state.newProjectData.buildings[i].buildingId);
        }
      }
      // Reset any buildings that are not main buildings and sever the link to the parent of any orphaned buildings
      if (!state.newProjectData.buildings[i].liegenshaft) {
        state.newProjectData.buildings[i].liegenshaftInformation = {
          firstName: '',
          surname: '',
          title: '',
          phoneNumber: '',
          email: '',
        };
      }
      Vue.set(state.newProjectData.buildings, i, state.newProjectData.buildings[i]);
    }
  },
  setProjectParentId(state, parentProjectId: string | null) {
    state.newProjectData.parentProjectId = parentProjectId;
  },
  setProjectId(state, projectId: string | null) {
    state.projectId = projectId;
  },
  setCommentBuildingIds(state, { buildingIds, value }: { buildingIds: number[]; value: boolean }) {
    for (let i = 0; i < buildingIds.length; i++) {
      state.buildingsWithCommentsMap[buildingIds[i]] = value;
    }
  },
  saveBuildingComment(state, comment: string) {
    const buildingIdsToSaveTo = state.buildingsWithCommentsMap;
    if (state.commentTabActiveBuildingId !== null) {
      buildingIdsToSaveTo[state.commentTabActiveBuildingId] = true;
    }
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (
        buildingIdsToSaveTo[state.newProjectData.buildings[i].buildingId] !== undefined &&
        buildingIdsToSaveTo[state.newProjectData.buildings[i].buildingId]
      ) {
        state.newProjectData.buildings[i].comment = comment;
      }
    }
    // Clear the buildingsWithCommentsMap
    state.buildingsWithCommentsMap = [];
  },
  selectMainAndSubBuildingsForComments(state, { buildingId, value }: { buildingId: number; value: boolean }) {
    const allBuildings = state.newProjectData.buildings;
    const oldBuildingsWithCommentsMap = state.buildingsWithCommentsMap;
    for (let i = 0; i < allBuildings.length; i++) {
      if (allBuildings[i].buildingId === buildingId || allBuildings[i].parentProperty === buildingId) {
        oldBuildingsWithCommentsMap[allBuildings[i].buildingId] = value;
      }
      if (allBuildings[i].liegenshaft && allBuildings[i].buildingId === buildingId) {
        state.selectedMainBuildingsForComments[buildingId] = value;
      }
    }
    state.buildingsWithCommentsMap = oldBuildingsWithCommentsMap;
  },
};

// Actions
const actions: ActionTree<ProjectCreateState, EnterpriseActions> = {
  // Saving functions
  async saveToDB({ state, commit, dispatch }, params: SaveToDBParams) {
    commit('setSaving', true);
    // First convert the state data
    const transformedObject = transformStateForSaving(state.newProjectData);
    // Add all the completed state variables to objectToSave
    const objectToSave = {
      ...transformedObject,
      generalInformationCompleted: state.generalInformationCompleted,
      buildingsImportCompleted: state.buildingsImportCompleted,
      propertyInformationCompleted: state.propertyInformationCompleted,
      propertyAssociationCompleted: state.propertyAssociationCompleted,
      originalMainBuildingIds: state.originalMainBuildingIds,
      originalBuildingIds: state.originalBuildingIds,
      omittedBuildingIds: state.omittedBuildingIds,
    };

    const buildingIds: number[] = [];
    const transformedBuildings = [...(transformedObject?.mainBuildings ?? []), ...(transformedObject?.buildings ?? [])];
    const originalBuildings = [
      ...(state.originalSaveObject?.mainBuildings ?? []),
      ...(state.originalSaveObject?.buildings ?? []),
    ];

    // Check with buildings have changed
    for (let i = 0; i < transformedBuildings.length; i++) {
      // Get the same building in the original object
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const transformedBuilding = transformedBuildings[i];
      const originalBuilding = originalBuildings.find((building) => building.id === transformedBuilding.id);

      if (originalBuilding) {
        const changed = hasObjectChanged(originalBuilding, transformedBuilding);

        if (changed) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          buildingIds.push(originalBuilding.id);
        }
      }
    }
    updateBuildingChangeListByIds(state, buildingIds);

    const { errors } = await params.apolloClient.mutate({
      mutation: saveProjectDraft,
      variables: {
        input: {
          pageContent: objectToSave,
          pageReference: 'full',
          projectReference: state.newProjectData.projectReference,
        },
      } as SaveProjectDraftMutationVariables,
    });

    if (params.finalSave) {
      // First wait a second before calling the final save
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await dispatch('saveToDBFinal', params.apolloClient);
    }

    commit('setSaving', false);
  },
  // Saving functions
  async saveToDBFinal({ state, commit }, apolloClient: DollarApollo<Vue>) {
    commit('setSaving', true);

    let updatedBuildingReferenceIds: string[] | undefined;

    if (state.buildingChangeList && state.buildingChangeList.size > 0) {
      updatedBuildingReferenceIds = Array.from(state.buildingChangeList.values());
    }
    // If there are existing buildings are no buildings to update, we can return
    if (state.editableBuildings && state.editableBuildings.size > 0 && !updatedBuildingReferenceIds) {
      commit('setSaving', false);
      return;
    }
    const { errors } = await apolloClient.mutate({
      mutation: createBuildings,
      variables: {
        input: {
          projectReference: state.newProjectData.projectReference,
          updatedBuildingReferenceIds,
        },
      } as CreateBuildingsMutationVariables,
    });
    commit('setSaving', false);
  },
  async setUpdateTab(
    { commit, getters },
    tab: 'step1' | 'step2' | 'step3' | 'elevatorSelection' | 'buildingContactInformation' | 'comments',
  ) {
    if (tab === 'buildingContactInformation') {
      state.newProjectData.buildings.forEach((building) => {
        if (building.liegenshaft) {
          building.liegenshaftInformationCompleted = false;
        }
      });
    }
    let newActiveBuildingId = null;
    const firstProperty = state.newProjectData.buildings.find((building) => building.liegenshaft);
    if (firstProperty?.buildingId) {
      newActiveBuildingId = firstProperty?.buildingId;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const buildingsWithElevators: ProjectBuilding[] = getters?.buildingsWithElevators as ProjectBuilding[];
    // If the tab is elevatorSelection the use buildingsWithElevators
    if (tab === 'elevatorSelection' && buildingsWithElevators?.length > 0) {
      if (buildingsWithElevators[0].buildingId) {
        newActiveBuildingId = buildingsWithElevators[0].buildingId;
      }
    }
    if (newActiveBuildingId) {
      commit('setActiveStep2BuildingId', newActiveBuildingId);
    }

    if (tab === 'comments') {
      const firstCommentProperty = state.newProjectData.buildings.find(
        (building) => building.liegenshaft && building.includesComments,
      );
      if (firstCommentProperty) {
        commit('setActiveStep2BuildingId', firstCommentProperty.buildingId);
      }
    }
    commit('setTab', tab);
  },
  async hydrateProjectDraftData(
    { commit, dispatch },
    projectData: DatabaseProjectDraft & ProjectDraftCompleted & EditOptions,
  ) {
    // First convert the state data
    const freshProjectData = structuredClone(projectData);
    const originalData = structuredClone(projectData);
    const transformedData = transformDbObjectForState(freshProjectData);
    commit('setOriginalData', originalData);
    commit('setNewProjectData', transformedData);
    commit('setNewBuildingsData', transformedData.buildings);
    commit('setEditableBuildings', freshProjectData.editableBuildings);
    commit('setBuildingChangeMode', freshProjectData.buildingChangeMode);
    commit('setNumberOfNonEditableBuildings', freshProjectData.numberOfNonEditableBuildings);
    commit('setBuildingsCreated', freshProjectData.buildingsCreated);

    if (transformedData.generalInformationCompleted) {
      commit('setGeneralInformationCompleted', true);
    }
    if (transformedData.propertyAssociationCompleted) {
      commit('setPropertyAssociationCompleted', true);
    }
    if (transformedData.buildings.some((building) => building.liegenshaft)) {
      if (transformedData.buildingsImportCompleted) {
        commit('setBuildingsImportCompleted', true);
      }
      if (transformedData.propertyInformationCompleted) {
        commit('setPropertyInformationCompleted', true);
      }
    }
    if (transformedData.projectType !== EnterpriseProjectType.ORIGINAL) {
      commit('setCopyDetails', {
        projectType: transformedData.projectType,
        originalMainBuildingIds: transformedData.originalMainBuildingIds,
        originalBuildingIds: transformedData.originalBuildingIds,
        omittedBuildingIds: transformedData.omittedBuildingIds,
      });
    }
  },
  // Step 1
  async setGeneralInformationCompleted({ commit }, completed: boolean) {
    commit('setGeneralInformationCompleted', completed);
  },
  async saveNewProjectData({ commit }, newProjectData: NewProjectData) {
    commit('setNewProjectData', newProjectData);
  },
  async saveNewBuildingData({ commit }, buildings: ProjectBuilding[]) {
    commit('setNewBuildingsData', buildings);
    updateBuildingChangeListByIds(
      state,
      buildings.map((building) => building.buildingId),
    );
  },
  async setBuildingsImportCompleted({ commit }, completed: boolean) {
    commit('setBuildingsImportCompleted', completed);
  },
  // eslint-disable-next-line complexity
  async saveBuildingInformation(
    { commit, dispatch },
    {
      buildingInformation,
      apolloClient,
      continueToNextBuilding,
    }: {
      buildingInformation: ProjectBuilding;
      apolloClient: DollarApollo<Vue>;
      liegenshaftInformationCompleted: boolean;
      continueToNextBuilding: boolean;
    },
  ) {
    const buildings = state.newProjectData.buildings;
    const selectedBuildingIds = [];
    for (let j = 0; j < buildings.length; j++) {
      if (
        state.selectedBuildingsForContactInformation[buildings[j].buildingId] &&
        !(
          state.buildingsCreated &&
          state.editableBuildings &&
          !state.editableBuildings.has(`${buildings[j].buildingId}`)
        )
      ) {
        selectedBuildingIds.push(buildings[j].buildingId);
      }
    }
    // Add active building id to selectedBuildingIds
    if (state.activeStep2BuildingId) {
      selectedBuildingIds.push(state.activeStep2BuildingId);
    }
    // This will set the information for the active building and all those selected
    for (let i = 0; i < selectedBuildingIds.length; i++) {
      commit('setBuildingInformationForBuildingId', { buildingInformation, buildingId: selectedBuildingIds[i] });
    }
    if (state.activeStep2BuildingId && continueToNextBuilding) {
      for (let i = 0; i < buildings.length; i++) {
        // If the buildingId is not in buildingIds
        if (
          buildings[i].liegenshaft &&
          !selectedBuildingIds.includes(buildings[i].buildingId) &&
          !buildings[i].liegenshaftInformationCompleted
        ) {
          commit('setActiveStep2BuildingId', buildings[i].buildingId);

          return;
        }
      }
    }
    commit('setPropertyInformationCompleted', true);
    await dispatch('saveToDB', { apolloClient, finalSave: false });
  },
  async updateAllBuildingInformation({ commit, dispatch }, buildingInformation: ProjectBuilding) {
    commit('setAllBuildingInformation', buildingInformation);
    commit('setPropertyInformationCompleted', true);

    await dispatch('setUpdateTab', 'step2');
  },
  // Step 2
  async setActiveStep2BuildingId({ state, commit }, id: number) {
    commit('setActiveStep2BuildingId', id);
  },
  async saveBuildingAssociations({ commit }, updatedBuilding: ProjectBuilding) {
    commit('saveBuildingAssociations', updatedBuilding);
  },
  async getNextActiveBuildingForStep2({ commit, dispatch }, apolloClient: DollarApollo<Vue>) {
    const buildings = state.newProjectData.buildings;
    let found = false;
    for (let i = 0; i < buildings.length; i++) {
      if (buildings[i].buildingId === state.activeStep2BuildingId) {
        found = true;
        continue;
      }
      if (buildings[i].liegenshaft && found) {
        commit('setActiveStep2BuildingId', buildings[i].buildingId);
        return;
      }
    }

    const liegenshafts = buildings.filter((building) => building.liegenshaft);

    // if we are on the last liegenschaft, move to the first one
    if (liegenshafts[liegenshafts.length - 1].buildingId === state.activeStep2BuildingId) {
      commit('setActiveStep2BuildingId', liegenshafts[0].buildingId);
    }
    // Set property association completed to true
    commit('setPropertyAssociationCompleted', true);
    // We for now, navigate to the next tab if no more liegenshaft buildings are found
    commit('setTab', 'step3');
    await dispatch('saveToDB', { apolloClient, finalSave: false });
  },
  async setPropertyAssociationCompleted({ commit }, completed: boolean) {
    commit('setPropertyAssociationCompleted', completed);
  },
  async assignActiveBuildingWIENumber({ commit }, wieNumber: string) {
    commit('updateBuildingWIENumber', { wieNumber, buildingId: state.activeStep2BuildingId });
  },
  assignActiveBuildingInstallationStartDate({ commit }, installationStartDate: string) {
    commit('updateBuildingInstallationStartDate', {
      installationStartDate,
      buildingId: state.activeStep2BuildingId,
    });
  },
};

// Getters
const getters: GetterTree<ProjectCreateState, RootStore> = {
  currentTab: (state) => state.tab,
  newProjectData: (state) => state.newProjectData,
  activeStep2BuildingId: (state) => state.activeStep2BuildingId,
  activeBuilding: (state) =>
    state.newProjectData.buildings.find((building) => building.buildingId === state.activeStep2BuildingId),
  liegenschaftenWithEmptyConfigurations: (): ProjectBuilding[] =>
    state.newProjectData.buildings.filter((building) => building.liegenshaft && !buildingHasConfigurations(building)),
  buildingListForStep1InformationCopy: (state) => {
    return state.newProjectData.buildings.filter((building: ProjectBuilding) => {
      return (
        !(
          state.buildingsCreated &&
          state.editableBuildings &&
          !state.editableBuildings.has(`${building.buildingId}`)
        ) &&
        building.liegenshaft &&
        building.buildingId !== state.activeStep2BuildingId
      );
    });
  },
  // Get the liegenshaft that have auzug set
  buildingsWithElevators: (state) => {
    return state.newProjectData.buildings.filter((building) => {
      if (!building.liegenshaft) {
        return false;
      }
      if (building.useCases) {
        return building.useCases.some((useCase) => {
          return (
            useCase.useCaseType === EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING ||
            useCase.useCaseType === EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE
          );
        });
      }
      return false;
    });
  },
  // Active liegenshaft with associated buildings
  activeLiegenschaftWithAssociatedBuildings: (state) => {
    const activeBuilding = state.newProjectData.buildings.find(
      (building) => building.buildingId === state.activeStep2BuildingId,
    );
    if (activeBuilding) {
      const linkedBuildings = state.newProjectData.buildings.filter(
        (building) => building.parentProperty === activeBuilding.buildingId,
      );
      return [activeBuilding, ...linkedBuildings];
    }
    return [];
  },
  // Check if every liegenshaft, and its associated buildings have at least one building with hasElevator set as true
  allBuildingsHaveElevator: (state) => {
    const mainBuildings = state.newProjectData.buildings.filter((building) => building.liegenshaft);
    // Filter mainBuildings to only include those that have auzug use case
    const filteredMainBuildings = mainBuildings.filter((building) => {
      return building.useCases?.some(
        (useCase) =>
          useCase.useCaseType === EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING ||
          useCase.useCaseType === EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE,
      );
    });
    for (const mainBuilding of filteredMainBuildings) {
      const associatedBuildings = state.newProjectData.buildings.filter(
        (building) => building.parentProperty === mainBuilding.buildingId,
      );
      // Check if any associated buildings have an elevator
      const gebaudeHasElevator = associatedBuildings.some((building) => building.hasElevator);

      if (!gebaudeHasElevator && !mainBuilding.hasElevator) {
        return false;
      }
    }
    return true;
  },
  buildingInformationFormValid: (state) => state.buildingInformationFormValid,
  isCopy: (state) => state.newProjectData.projectType !== EnterpriseProjectType.ORIGINAL,
  projectType: (state) => state.newProjectData.projectType,
  originalMainBuildingIds: (state) => state.originalMainBuildingIds,
  omittedBuildingIds: (state) => state.omittedBuildingIds,
  // eslint-disable-next-line complexity
  newProductSelection(state) {
    const selection: NewProductSelection = {
      mainService: null,
      infrastructureVariant: null,
      heating: null,
      elevator: null,
    };
    // We loop through all the main buildings and add the selections to the object. We set the first value we find
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].liegenshaft) {
        if (state.newProjectData.buildings[i].mainService || !selection.mainService) {
          const mainServiceSelection = mainServiceOptions.find(
            (option) => option.value == state.newProjectData.buildings[i].mainService,
          );
          if (mainServiceSelection) {
            selection.mainService = mainServiceSelection.value;
          }
        }
        if (state.newProjectData.buildings[i].products) {
          const buildingProducts = state.newProjectData.buildings[i].products;
          if (buildingProducts) {
            // Find the infrastructure variant
            const infrastructureProduct = buildingProducts.find(
              // Check if the option is a InfrastructureVariantType
              (product) =>
                product.option?.toUpperCase() === EnterpriseProjectObjectProductOption.PRO ||
                product.option?.toUpperCase() === EnterpriseProjectObjectProductOption.BASIS ||
                product.option?.toUpperCase() === EnterpriseProjectObjectProductOption.BASIS_PLUS ||
                product.option?.toUpperCase() === EnterpriseProjectObjectProductOption.START,
            );
            if (infrastructureProduct && infrastructureProduct.option) {
              selection.infrastructureVariant =
                infrastructureProduct.option.toUpperCase() as EnterpriseProjectObjectProductOption;
            }
          }
        }
        if (state.newProjectData.buildings[i].useCases) {
          const buildingUseCases = state.newProjectData.buildings[i].useCases;
          if (buildingUseCases) {
            // Find the heating use case
            const heatingUseCase = buildingUseCases.find(
              // Check if the option is a heating use case
              (useCase) =>
                useCase.useCaseType === EnterpriseProjectObjectUseCase.HEATING_MONITORING ||
                useCase.useCaseType === EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL,
            );
            if (heatingUseCase && heatingUseCase.useCaseType) {
              selection.heating = heatingUseCase.useCaseType as
                | EnterpriseProjectObjectUseCase.HEATING_MONITORING
                | EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL
                | null;
            }
            // Find the elevator use case
            const elevatorUseCase = buildingUseCases.find(
              // Check if the option is a elevator use case
              (useCase) =>
                useCase.useCaseType === EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING ||
                useCase.useCaseType === EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE,
            );
            if (elevatorUseCase && elevatorUseCase.useCaseType) {
              selection.elevator = elevatorUseCase.useCaseType as
                | EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE
                | EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING
                | null;
            }
          }
        }
      }
    }
    return selection;
  },
  projectId(state) {
    return state.projectId;
  },
  projectCopyChangesId(state) {
    return state.projectCopyChangesId;
  },
  projectHasElevatorProduct(state) {
    return projectHasElevatorProduct(state.newProjectData.buildings);
  },
  mainBuildingsWithComments(state) {
    return state.newProjectData.buildings.filter((building) => building.liegenshaft && building.includesComments);
  },
  buildingsWithCommentsMap(state) {
    return state.buildingsWithCommentsMap;
  },
  allMainBuildingInformationCompleted(state) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].liegenshaft) {
        if (
          !state.newProjectData.buildings[i].liegenshaftInformation?.title ||
          !state.newProjectData.buildings[i].liegenshaftInformation?.firstName ||
          !state.newProjectData.buildings[i].liegenshaftInformation?.surname ||
          !state.newProjectData.buildings[i].liegenshaftInformation?.phoneNumber ||
          !state.newProjectData.buildings[i].liegenshaftInformation?.email
        ) {
          return false;
        }
      }
    }
    return true;
  },
  // eslint-disable-next-line complexity
  allMainBuildingProductInformationIsValid(state) {
    const mainBuildings = state.newProjectData.buildings.filter((building) => building.liegenshaft);
    for (let i = 0; i < mainBuildings.length; i++) {
      if (!doesBuildingHaveInfo(mainBuildings[i], state.newProjectData.projectType)) {
        return false;
      }
    }
    return true;
  },
  editableBuildings(state) {
    return state.editableBuildings;
  },
  selectedMainBuildingsForComments(state) {
    return state.selectedMainBuildingsForComments;
  },
};

// Export the module
export const EnterpriseProjectCreateModule: Module<ProjectCreateState, any> = {
  state,
  mutations,
  actions,
  getters,
};
