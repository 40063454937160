import moment from 'moment';
import { checkMetricName } from './metric-calculations';
import numeral from '@/util/numeral-locale';

export interface FormatOptions {
  decimalsRound?: number;
}

const germanLocaleNumberFormatter = new Intl.NumberFormat('de-DE');

const branch = (ifValue: string, elseValue: string) => (value: string | number) =>
  value === '1' || value === 1 ? ifValue : elseValue;
const yesNo = branch('Ja', 'Nein');
const onOff = branch('An', 'Aus');
const continuous = (value: string | number, { decimalsRound = 2 } = {}): number =>
  Math.round(Number(value) * Math.pow(10, decimalsRound)) / Math.pow(10, decimalsRound);

const continuousPercent = (value: string | number, { decimalsRound = 2 } = {}): number =>
  Math.round(Number(value) * 100 * Math.pow(10, decimalsRound)) / Math.pow(10, decimalsRound);

const date = (value: string | number): string => moment(value).format('LLL');
const id = (value: string | number): string => String(value);

interface MetricFormatConfig {
  translation: string;
  formatter?: (value: string | number, options?: FormatOptions) => string | number;
  unit?: string;
  convert?: (value: number) => number;
}

export const metricUnitsMap = new Map<string, string>([
  ['celsius', '°C'],
  ['cubic_meter', 'm³'],
  ['killowatt_hour', 'kWh'],
  ['volt', 'V'],
  ['percent', '%'],
  ['litre', 'l'],
  ['second', 's'],
  ['cubic_meter_per_hour', 'm³/h'],
  ['kelvin', 'K'],
  ['litre_per_hour', 'l/h'],
  ['watt', 'W'],
  ['ampere', 'A'],
  ['decibel', 'dBm'],
  ['hectopascal', 'hPa'],
  ['parts_per_million', 'ppm'],
]);

export const formatConfig: Record<string, MetricFormatConfig> = {
  opened: { translation: 'Geöffnet', formatter: yesNo },
  openedCount: { translation: 'Zahl der Öffnungen', formatter: continuous },
  closed: { translation: 'Geschlossen', formatter: yesNo },
  tamper: { translation: 'Manipuliert', formatter: yesNo },
  batteryLow: { translation: 'Batterie niedrig', formatter: yesNo },
  consumption: { translation: 'Zählerstand', formatter: continuous, unit: 'cubic_meter' },
  glasbreak: { translation: 'Glasbruch', formatter: yesNo },
  currentConsumption: { translation: 'Zählerstand', formatter: continuous },
  currentEnergy: {
    translation: 'Energieverbrauch',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  energyMonth: {
    translation: 'energyMonth',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  currentEnergyCold: {
    translation: 'Kältezähler',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  energyColdMonth: {
    translation: 'energyColdMonth',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  receivedEnergy: {
    translation: 'Einspeisung',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  currentEnergyTarif1: {
    translation: 'Haupttarif',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  currentEnergyTarif2: {
    translation: 'Nebentarif',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  flowTemperature: { translation: 'Heizungstemperatur', formatter: continuous, unit: 'celsius' },
  externalTemperature: { translation: 'Raumtemperatur', formatter: continuous, unit: 'celsius' },
  leakage: { translation: 'Leckage', formatter: yesNo },
  onoff: { translation: 'An/Aus', formatter: onOff },
  monoxid: { translation: 'Kohlenmonoxid', formatter: branch('Schlecht', 'Okay') },
  movement: { translation: 'Detektor', formatter: branch('Bewegung', 'Ruhe') },
  occupancy: { translation: 'Verfügbarkeit', formatter: branch('Belegt', 'Frei') },
  lastAlarm: { translation: 'Letzter Alarm', formatter: date },
  alarmCount: { translation: 'Anzahl Alarme', formatter: continuous },
  fraudCount: { translation: 'Beschädigungsversuche', formatter: continuous },
  batteryVoltage: { translation: 'Batterie', formatter: continuous, unit: 'volt' },
  dustLevel: { translation: 'Verschmutzung', formatter: continuous },
  removalCount: { translation: 'Anzahl der Demontagen', formatter: continuous },
  lastRemoval: { translation: 'Letzte Demontage', formatter: date },
  lastFraud: { translation: 'Letzter Beschädigungsversuch', formatter: date },
  removed: { translation: 'Montiert', formatter: yesNo },
  alarm: { translation: 'Alarm', formatter: yesNo },
  isInFraudState: { translation: 'Beschädigungsversuch', formatter: yesNo },
  temperature: { translation: 'Temperatúr', formatter: continuous, unit: 'celsius' },
  humidity: { translation: 'Luftfeuchtigkeit', formatter: continuous, unit: 'percent' },
  currentVolume: { translation: 'Volumen', formatter: continuous, unit: 'cubic_meter' },
  currentVolume_calc: { translation: 'Volumen', formatter: continuous, unit: 'cubic_meter' },
  oilTankVolumeLiters: { translation: 'Tankgröße', formatter: continuous, unit: 'litre' },
  fillingLevelLiters: { translation: 'Volumen', formatter: continuous, unit: 'litre' },
  fillingLevelCm: { translation: 'Volumen', formatter: continuous, unit: 'cubic_meter' },
  fillingLevelPercentage: {
    translation: 'Füllstand',
    formatter: continuousPercent,
    unit: 'percent',
  },
  ELECTRICITY_METER_READING: {
    translation: 'Zählerstand',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },

  alarmTest: { translation: 'Alarm Test', formatter: yesNo },
  alarmTestMaxDuration: { translation: 'Alarm Test Dauer', formatter: continuous, unit: 'second' },
  applianceSupplyTemperature: {
    translation: 'Vorlauftemperatur',
    formatter: continuous,
    unit: 'celsius',
  },
  batteryDefect: { translation: 'Batterie defekt', formatter: yesNo },
  currentFlow: { translation: 'Durchfluss', formatter: continuous, unit: 'cubic_meter_per_hour' },
  endOfLife: { translation: 'Lebensende', formatter: yesNo },
  fraudDuration: { translation: 'Manipulationsdauer', formatter: continuous, unit: 'second' },
  headConnected: { translation: 'Head verbunden', formatter: yesNo },
  headFault: { translation: 'Head fehlerhaft', formatter: yesNo },
  illuminance: { translation: 'Beleuchtungsstärke', formatter: continuous },
  lastMonthEnergy: {
    translation: 'Zählerstand Vormonat',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  lastMonthVolume: { translation: 'Volumen Vormonat', formatter: continuous, unit: 'cubic_meter' },
  lastPeriodEnd: { translation: 'Stichtag', formatter: date },
  currentTime: { translation: 'Aktuelle Uhrzeit', formatter: date },
  lastPeriodEnergy: {
    translation: 'Zählerstand zum Stichtag',
    formatter: continuous,
    unit: 'killowatt_hour',
    convert: (v: number) => v / 1000,
  },
  lastPeriodVolume: {
    translation: 'Volumen zum Stichtag',
    formatter: continuous,
    unit: 'cubic_meter',
  },
  lightHue: { translation: 'Farbwert', formatter: continuous },
  lightLevel: { translation: 'Lichtniveau', formatter: continuous },
  meterId: { translation: 'Zähler ID', formatter: id },
  networkLinkStrength: { translation: 'Netzwerkstärke', formatter: continuous },
  notifications: { translation: 'Benachrichtigungen', formatter: yesNo },
  online: { translation: 'Verbunden', formatter: yesNo },
  outdoorTemperature: { translation: 'Außentemperatur', formatter: continuous, unit: 'celsius' },
  ready: { translation: 'Bereit', formatter: yesNo },
  removalDuration: { translation: 'Demontagendauer', formatter: continuous, unit: 'second' },
  returnTemperature: { translation: 'Rücklauftemperatur', formatter: continuous, unit: 'celsius' },
  rgbLevel: { translation: 'RGB Level', formatter: continuous },
  rgbOnoff: { translation: 'RGB An/Aus', formatter: onOff },
  trouble: { translation: 'Probleme', formatter: yesNo },

  lightColorTemperature: { translation: 'Farbtemperatur', formatter: continuous, unit: 'kelvin' },
  actualCHPower: { translation: 'actualCHPower', formatter: continuous },
  actualDHWPower: {
    translation: 'actualDHWPower',
    formatter: continuous,
  },
  actualPower: { translation: 'actualPower', formatter: continuous },
  actualModulation: { translation: 'Modulation', formatter: continuous },
  supplyTemperatureSetpoint: {
    translation: 'Zulauftemperatureinstellung',
    formatter: continuous,
    unit: 'celsius',
  },
  commsLinkSuccessful: { translation: 'Kommunikation', formatter: branch('verbunden', 'unterbrochen') },
  sounderFlag: { translation: 'Rauchmelderton', formatter: branch('angeschlossen', 'nicht angeschlossen') },
  meanCirculationTemperature: {
    translation: 'Vorlauftemperatur',
    formatter: continuous,
    unit: 'celsius',
  },
  meanReservoirTemperature: {
    translation: 'Rücklauftemperatur',
    formatter: continuous,
    unit: 'celsius',
  },
  systemFlow: { translation: 'Durchfluss', formatter: continuous, unit: 'litre_per_hour' },
  percentage: { translation: 'Anteil', formatter: continuousPercent, unit: 'percent' },
  voc: { translation: 'VOC', formatter: continuous },
  minVoc: { translation: 'Min VOC', formatter: continuous },
  maxVoc: { translation: 'Max VOC', formatter: continuous },
  relativeChange: {
    translation: 'Relative Änderung',
    formatter: continuousPercent,
    unit: 'percent',
  },
  carALoadPercent: {
    translation: 'WagenA Ladung Prozent',
    formatter: continuousPercent,
    unit: 'percent',
  },
  carALoadState: { translation: 'WagenA Ladung Status' },
  carAMovingDirection: { translation: 'WagenA Bewegungsrichtung' },
  carAMovingState: { translation: 'WagenA Bewegungsstatus' },
  carBLoadPercent: {
    translation: 'WagenB Ladung Prozent',
    formatter: continuousPercent,
    unit: 'percent',
  },
  carBLoadState: { translation: 'WagenB Ladung Status' },
  carBMovingDirection: { translation: 'WagenB Bewegungsrichtung' },
  carBMovingState: { translation: 'WagenB Bewegungsstatus' },
  liftState: { translation: 'Status' },
  doorCycles: { translation: 'Türzyklen', formatter: continuous },
  liftRuns: { translation: 'Fahrten', formatter: continuous },
  doorOpenings: { translation: 'Türöffnungen', formatter: continuous },
  doorClosings: { translation: 'Türschließungen', formatter: continuous },
  boilerTemperature: { translation: 'Boilertemperatur', formatter: continuous, unit: 'celsius' },
  circuitSupplyTemperature: {
    translation: 'Vorlauftemperatur',
    formatter: continuous,
    unit: 'celsius',
  },
  outsideTemperature: { translation: 'Außentemperatur', formatter: continuous, unit: 'celsius' },
  dhwHotWaterStorageTemperature: {
    translation: 'Heißwasserspeicher Speichertemperatur',
    formatter: continuous,
    unit: 'celsius',
  },
  con: {
    translation: 'Verbrauch',
    formatter: continuous,
    unit: 'watt',
  },
  i_avg: {
    translation: 'Durchschnittlicher Strom',
    formatter: continuous,
    unit: 'ampere',
  },
  i_max: {
    translation: 'Maximaler Strom',
    formatter: continuous,
    unit: 'ampere',
  },
  i_min: {
    translation: 'Minimaler Strom',
    formatter: continuous,
    unit: 'ampere',
  },
  v_avg: {
    translation: 'Durchschnittliche Spannung',
    formatter: continuous,
    unit: 'volt',
  },
  v_max: {
    translation: 'Maximale Spannung',
    formatter: continuous,
    unit: 'volt',
  },
  v_min: {
    translation: 'Minimale Spannung',
    formatter: continuous,
    unit: 'volt',
  },
  rssi: {
    translation: 'Funksignalqualität',
    formatter: continuous,
    unit: 'decibel',
  },
  state: { translation: 'Status' },
  atmospheric_pressure: {
    translation: 'Luftdruck',
    formatter: continuous,
    unit: 'hectopascal',
  },
  battery_ok: {
    translation: 'Batteriestatus',
    formatter: continuous,
  },
  co2_gas: {
    translation: 'CO2',
    formatter: continuous,
    unit: 'parts_per_million',
  },
  message_type: {
    translation: 'Nachrichtentyp',
  },
  imei: {
    translation: 'Geräte ID',
  },
  rsrpNB: {
    translation: 'Signalstärke Mobilfunk (NarrowBand)',
    unit: 'dBm',
  },
  resrqNB: {
    translation: 'Signalqualität Mobilfunk (NarrowBand)',
    unit: 'dB',
  },
  sinrNB: {
    translation: 'SINR (NarrowBand)',
    unit: 'dB',
  },
  rsrpLTE: {
    translation: 'Signalstärke Mobilfunk (LTE)',
    unit: 'dBm',
  },
  resrqLTE: {
    translation: 'Signalqualität Mobilfunk (LTE)',
    unit: 'dB',
  },
  sinrLTE: {
    translation: 'SINR (LTE)',
    unit: 'dB',
  },
  rssiGSM: {
    translation: 'Signalqualität GSM',
    unit: 'dB',
  },
  currentOutputPower: {
    translation: 'currentOutputPower',
    unit: 'W',
  },
  currentOutputCurrent: {
    translation: 'currentOutputCurrent',
    unit: 'A',
  },
  currentOutputVoltage: {
    translation: 'currentOutputVoltage',
    unit: 'V',
  },
  frequency: {
    translation: 'frequency',
    unit: 'Hz',
  },
  currentInputCurrent: {
    translation: 'currentInputCurrent',
    unit: 'A',
  },
  currentInputVoltage: {
    translation: 'currentInputVoltage',
    unit: 'V',
  },
  currentCurrent: {
    translation: 'currentCurrent',
    unit: 'A',
  },
  currentVoltage: {
    translation: 'currentVoltage',
    unit: 'V',
  },
  errorCode: {
    translation: 'errorCode',
  },
  statusCode: {
    translation: 'statusCode',
  },
};

const indexRegex = /\d+$/;

function findMetricFormatConfig(metricName: string): MetricFormatConfig | undefined {
  const isCalculationMetricName = metricName.includes('_calc');
  const cleanMetricName = checkMetricName(metricName);

  const config = formatConfig[cleanMetricName] ?? formatConfig[cleanMetricName.replace(indexRegex, '')];
  if (isCalculationMetricName) {
    config.translation = config.translation.includes('(Kalkulation)')
      ? config.translation
      : `${config.translation} (Kalkulation)`;
  }

  return config;
}

export function isContinuous(metricName: string): boolean {
  const config = findMetricFormatConfig(metricName);

  return config?.formatter === continuous || config?.formatter === continuousPercent;
}

export function isBooleanMetric(metricName: string): boolean {
  const config = findMetricFormatConfig(metricName);

  return config?.formatter === yesNo || config?.formatter === onOff;
}

export function formatMetricName(metricName: string): string {
  const translation = findMetricFormatConfig(metricName)?.translation;
  if (translation === undefined) {
    return metricName;
  }
  const isMetricOfHeatMeterCounter = metricName.startsWith('energyColdMonth') || metricName.startsWith('energyMonth');
  const isMetricOfElectricMeterDigital = metricName.startsWith('currentEnergyTarif');
  let index = indexRegex.exec(metricName)?.[0] ?? '';

  //in case of the "heat meter counter": metric names to be prepended with 0 for sorting
  //in case of the "electricity meter": metric names to skip showing the index
  index =
    isMetricOfHeatMeterCounter && index.length > 0
      ? index.padStart(2, '0')
      : isMetricOfElectricMeterDigital
      ? ''
      : index;

  return `${translation} ${index}`.trim();
}

export function getMetricUnit(metricName: string): string {
  return metricUnitsMap.get(getMetricUnitName(metricName)) ?? '';
}

export function getMetricUnitName(metricName: string): string {
  return findMetricFormatConfig(metricName)?.unit ?? '';
}

export function getConverter(metricName: string): ((value: number) => number) | undefined {
  return findMetricFormatConfig(metricName)?.convert;
}

export function getFormatter(
  metricName: string,
): ((value: string | number, options?: FormatOptions) => string | number) | undefined {
  return findMetricFormatConfig(metricName)?.formatter;
}

export function formatMetricValue(
  metricName: string,
  metricValue: number | string,
  options?: FormatOptions,
  unit?: string | null,
): string {
  const config = findMetricFormatConfig(metricName);
  let formattedValue =
    config?.formatter?.(config?.convert?.(Number(metricValue)) ?? metricValue, options) ?? metricValue;

  const formattedNumber = formattedValue ? Number.parseFloat(`${formattedValue}`) : undefined;

  if (formattedNumber) {
    formattedValue = germanLocaleNumberFormatter.format(formattedNumber);
  }

  return `${formattedValue}\u202f ${unit ? unit : getMetricUnit(metricName)}`;
}
